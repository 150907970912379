.container {
  margin-top: .75em;
}

.button {
  border-width: 0.1em;
  border-radius: 0.375em;
  width: 7.5em;
  height: 2.25em;
  font-size: 0.875em;
}