.container {
  position: relative;
  width: 100%;
  height: 2.875em;
  font-size: 1.125em;
  font-family: 'Lato Regular';
  border-radius: .5em;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--button-color-primary);
  background-color: var(--button-bg-primary);
  overflow: hidden;

  &:hover {
    background-color: var(--button-bg-primary-hover);
  }

  &_ghost {
    color: var(--button-color-secondary);
    background-color: var(--button-bg-secondary);
    border: var(--button-border);

    &:hover {
      background-color: var(--button-bg-secondary-hover);
    }
  }

  &_disabled {
    background-color: var(--button-bg-disabled);
    color: var(--button-color-disabled);
    cursor: not-allowed;
    border: none;

    &:hover {
      background-color: var(--button-bg-disabled);
    }
  }

  &_loading {
    background-color: var(--button-bg-loading);

    &:hover {
      background-color: var(--button-bg-loading);
    }
  }
}
