@import "../../styles";

.container {
  background-color: var(--card-bg);
  margin-top: 4rem;
  margin-bottom: 4rem;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80%;
}

.title {
  font-family: "FredokaOne";
  font-size: 2.5em;
  line-height: 1.5em;
  padding-bottom: 0.6em;
  margin-top: 1em;
  text-align: center;

  @include mobile {
    font-size: 2em;
    padding-bottom: 0.5em;
  }
}

.subtitle {
  font-family: "FredokaOne";
  font-size: 1.5em;
  line-height: 1.5em;
  padding-bottom: 0.6em;
  margin-top: 0.5em;

  @include mobile {
    font-size: 1em;
    padding-bottom: 0.5em;
  }
}

.button {
  width: 30%;
  @include mobile {
    width: 100%;
    margin-top: 1rem;
  }
}

.chimpContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 80%;
}

.chimp {
  display: flex;
  flex-direction: column;
  width: 20%;
  margin-left: 1rem;
  margin-right: 1rem;
  @include mobile {
    width: 100%;
  }
}

.image {
  border-radius: 1rem;
}

.card {
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: center;
  width: 100%;
}