@import '../../styles';

.component {
  display: flex;
  padding: 0.875em 1em;

  &_warn {
    background-color: #FDDCE0;
    border: .0625em solid #F65166;
    color: #101629;
  
    svg path {
      fill: #101629;
    }
  }

  &_info {
    border: var(--border-tertiary);
    background: rgba(195, 198, 207, 0.2);
    color: var(--app-color);

    svg path {
      fill: var(--svg-fill-tertiary);
    }
  }
}

.component_icon {
  min-width: 0.9375em;
  height: 0.9375em;
  margin-top: 0.0625em;
  margin-right: .5em;
}

.component_text {
  font-size: 0.875em;
  font-family: 'Barlow Regular';

  @include mobile {
    font-size: 0.75em;
  }
}
