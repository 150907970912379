@import "../../styles";

.form_group {
  display: flex;
  flex-direction: column;
  font-family: "Lato Regular";
  max-width: 50em;
  position: relative;
}

.form_control {
  width: 100%;
  outline: 0;
  border: var(--border-tertiary);
  border-radius: 0.25em;
  background: #ffffff;
  font-size: 1.125em;
  height: 3.5em;
  padding: 0.875em 0.75em;
  color: #101629;

  &:focus {
    border: var(--border-secondary);
    border-width: 0.125em;
  }

  &:disabled {
    background-color: #e5e5e5;
  }
}

.form_control_input {
  &:focus,
  &:not(:placeholder-shown) {
    padding-top: 1.5em;
  }

  &:focus ~ label,
  &:not(:placeholder-shown) ~ label {
    top: 1.25em;
    left: 1em;
    text-transform: uppercase;
    font-size: 0.5625em;
  }
}

.form_control_select {
  padding: 1.25em 1.5em 0.75em 0.75em;
  -webkit-appearance: none;
  -moz-appearance: none;
  
  &:invalid {
    color: #FFFFFF;
  }

  [disabled] {
    color: #FFFFFF;
  }

  option {
    color: #101629;
  }
}

.active_label {
  text-transform: uppercase;
  transform: translate3d(-2.35em, -0.75em, 0em) scale(0.5)
}

.select_polygon {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 1.75em;
  right: 0.75em;
}

.control_label {
  padding: 0 0.875em;
  color: #767676;
  pointer-events: none;
  position: absolute;
  left: 0;
  top: 1em;
  transition: all 0.2s ease-out;
  font-size: 1.125em;
  line-height: 1.3125em;
}

.number_input {
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }
}

.error {
  background-color: #FDDCE0;
  border: 0.0625em solid #F65166;
  border-radius: 0.25em;
  padding: 0.875em 1em;
  color: #101629;
  font-size: 0.875em;
  margin-top: 0.5em;
  display: flex;
  align-items: center;
}

.error_icon {
  width: 100%;
  max-width: 1em;
  height: 1em;
  margin-right: .5em;
  fill: #101629;
}

input::-webkit-calendar-picker-indicator {
  opacity: 0;
}

input::-webkit-list-button {
  opacity: 0;
}
