@import "../../styles";

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--recall-bg);
}

.title {
  font-family: "FredokaOne";
  font-size: 2.5em;
  // line-height: 1.5em;
  padding-bottom: 0.6em;
  margin-top: 2em;
  text-align: center;
  width: 60%;

  @include mobile {
    font-size: 2em;
    padding-bottom: 0.5em;
    width: 100%;
  }
}

.card {
  background-color: var(--card-bg3);
  width: 80%;
  text-align: center;
  padding: 1.5em;
  border-radius: 1em;
  border: 2px solid #000;
  box-shadow: 0 6px 0 rgba(0, 0, 0, 1);

  @include mobile {
    margin-top: 16rem;
    width: 100%;
  }
}

.header {
  width: 45%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @include mobile {
    width: 80%;
  }
}

.middle {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.left {
  position: absolute;
  top: 10;
  left: 15%;
  z-index: 10;

  @include mobile {
    scale: 0.7;
    top: 30%;
  }
}

.build {
  position: absolute;
  top: 250px;
  right: 15%;
  z-index: 10;
  scale: 0.9;

  @include mobile {
    scale: 0.5;
    right: -50px;
    top: 45%;
  }
}

.load {
  position: absolute;
  top: 10;
  right: 8%;
  z-index: 10;
  scale: 1.1;
  @include mobile {
    transform: scaleX(-1);
    left:0;
    top: 45%;
    scale: 0.8;
  }

}