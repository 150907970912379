@import '../../styles';

.container {
  background: var(--app-bg);
  border-radius: 0.75em;
  width: fit-content;
  margin: auto;
  font-family: Lato Regular;
  overflow: hidden;
  max-height: inherit;
  position: relative;
  border: var(--border-primary);

  @include mobile {
    width: 100%;
  }
}

.header {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  top: 0;
  padding: 1.5em;
  z-index: calc($z-index-modal + 1);

  &_bg {
    background: var(--modal-header-bg);
  }

  @include mobile {
    padding: 1.125em;
  }
}

.title {
  font-weight: 600;
  font-size: 1.375em;
  line-height: 1.5em;

  @include mobile {
    font-size: 1em;
  }
}

.close {
  position: absolute;
  right: 1.875em;
  fill : var(--svg-fill-tertiary);

  @include mobile {
    right: 1.25em;
  }
}

.selectWrap {
  padding: 0em 2em 2em;
  overflow: auto;
  max-height: 40em;
  min-width: 25em;

  @include mobile {
    padding: 0em 1em 1em;
    min-width: unset;
  }
}

.selectChimps {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1.5em;
  padding-bottom: 2.5em;

  @include mobile {
    gap: 0.375em;
    padding-bottom: 1.5em;
  }
}

.chimpItem {
  text-align: center;
  cursor: pointer;
}

.chimpImg {
  position: relative;
  max-width: 8.75em;
  max-height: 8.75em;
  aspect-ratio: 1 / 1;
  border: 0.1875em solid transparent;
  border-radius: 0.75em;
  overflow: hidden;
  display: flex;

  &_selected {
    border-color: #FA6E2E;
  }

  img {
    width: 100%;
    height: 100%;
  }

  @include mobile {
    border-width: 0.125em;
    border-radius: 0.25em;
  }
}

.honoraryLabel_1,
.honoraryLabel_2 {
  position: absolute;
  top: 0.5em;
  right: 0.5em;
  background: #E1E3E7;
  color: #101629;
  line-height: 1.5em;
  border-radius: 0.25em;
  padding: 0.1em 0.4em;
}

.honoraryLabel_1 {
  font-size: 0.875em;

  @include mobile {
    font-size: 0.5em;
  }
}

.honoraryLabel_2 {
  font-size: 0.75em;

  @include mobile {
    font-size: 0.5em;
  }
}

.checkIcon {
  display: none;
  position: absolute;
  width: 1.25em;
  height: 1.25em;
  right: 0.5em;
  bottom: 0.5em;

  &_checked {
    display: initial;
  }

  @include mobile {
    width: 0.625em;
    height: 0.625em;
    right: 0.25em;
    bottom: 0.25em;
  }
}

.chimpId {
  font-weight: 600;
  font-size: 1.25em;
  line-height: 1.4em;

  @include mobile {
    font-size: 0.625em;
    line-height: 1.8em;
  }
}

.noSelectableChimps {
  padding-bottom: 2em;
  text-align: center;
  max-width: 25em;
  margin: auto;

  @include mobile {
    font-size: 0.875em;
  }
}

.blockTip {
  margin-bottom: 1em;
}

.confirmWrap {
  max-width: 28em;
  background: var(--modal-body-bg);
  overflow: auto;
  max-height: calc(100vh - 6.5em);
}

.selectedChimps {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1em;
  padding: 0 1.5em 1.75em;
  margin: auto;
  width: fit-content;
  background: var(--modal-header-bg);

  @include mobile {
    gap: 0.625em;
    padding: 0 1.25em 1em;
  }
}

.selectedChimp {
  position: relative;
  max-width: 5.5em;
  max-height: 5.5em;

  img {
    width: 100%;
    height: 100%;
    display: flex;
    border-radius: 0.25em;
  }
}

.confirmBody {
  background: var(--modal-body-bg);
  padding: 1.5em;
  border-radius: 0 0 0.75em 0.75em;
  display: flex;
  flex-direction: column;
  gap: 1em;

  @include mobile {
    padding: 1em;
  }
}

.dbp {
  text-align: center;
}

.dbpDesc {
  line-height: 1.75em;
  color: var(--label-color);
  margin: 0;
  font-weight: 600;

  @include mobile {
    font-size: 0.875em;
  }
}

.dbpValue {
  font-size: 1.25em;
  font-family: 'FredokaOne';
  line-height: 2em;
  letter-spacing: -0.02em;
  margin: 0 1em;

  @include mobile {
    font-size: 1.25em;
    line-height: 1.65em;
  }
}

.dbpChangeArrow {
  color: var(--label-color);
  letter-spacing: -0.02em;
}

.confirmDesc {
  display: flex;
  flex-direction: column;
  gap: 1em;
}

.textParag {
  font-size: 0.875em;
  line-height: 1.375em;
  margin: 0em;

  a {
    color: #F94F01;
    text-decoration: underline;
  }

  @include mobile {
    font-size: 0.75em;
    line-height: 1.5em;
  }
}

.statement {
  background: rgba(195, 198, 207, 0.2);
  border: var(--border-tertiary);
  padding: 0.375em 1em;
  font-size: 0.875em;
  color: var(--label-color);

  @include mobile {
    font-size: 0.75em;
  }
}

.confirmButton {
  height: 2.625em;
}

.confirmingWrap {
  text-align: center;
  min-width: 25em;
  padding: 2em 1.5em 3em;

  @include mobile {
    min-width: unset;
    padding: 2em 1.5em 2.5em;
  }
}

.confirmingImg {
  max-width: 13em;
}

.confirmingTitle {
  font-size: 1.375em;
  font-family: 'Lato Bold';
  line-height: 1.5em;

  @include mobile {
    font-size: 1.25em;
  }
}

.confirmingSubTitle {
  font-size: 0.875em;
  line-height: 1.5em;
  color: var(--sub-title-color);
  margin-top: 0.5em;
}
