@import '../../styles';

.container {
  position: relative;

  @include mobile {
    padding: 2.5em 1em 5em;
  }
}

.header {
  background: var(--page-header-bg);
  height: 23em;

  @include mobile {
    display: none;
  }
}

.headerInner {
  max-width: 60em;
  margin: auto;
  padding: 2.5em 0;
}

.goBack {
  display: flex;
  align-items: center;
  font-family: 'Barlow Regular';
  font-weight: 600;
  font-size: 1.125em;
  line-height: 2em;
  text-decoration-line: underline;
  color: inherit;
  width: fit-content;

  svg {
    margin-right: 0.75em;
    fill: var(--svg-fill-tertiary);
  }
}

.content {
  max-width: 60em;
  width: 100%;
  margin: auto;
  padding-bottom: 5em;
  margin-top: calc(9em - 23em);

  @include mobile {
    margin-top: 0em;
    padding-bottom: 0em;
  }
}

.claimForm {
  width: 100%;
  background: var(--form-bg);
  display: flex;
  flex-direction: column;
  padding: 2em;
  gap: 2em;

  @include mobile {
    padding: 0;
    background: transparent;
  }
}

.noGiftSelected {
  text-align: center;
  padding: 2em 1em;
  font-weight: 600;
}

.formTitle {
  font-size: 2em;
  line-height: 1.75em;
  font-family: 'FredokaOne';
  padding-bottom: 1.25em;

  @include mobile {
    font-size: 1.375em;
  }
}

.formBody {
  display: flex;
  align-items: stretch;
  padding: 0;
  gap: 1.5em;
  width: 100%;

  @include mobile {
    flex-direction: column;
    gap: 1em;
  }
}

.formCol {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 1.5em;
  gap: 2em;
  background: var(--card-bg);
  border-radius: 0.25em;
  border: var(--border-tertiary);

  &:first-child {
    flex: 1.13;
  }

  &:nth-child(2) {
    flex: 1;
  }

  @include mobile {
    width: 100%;
    padding: 1em;
    gap: 1.5em;
  }
}

.formLabel {
  display: block;
  font-weight: 500;
  font-size: 1.125em;
  line-height: 1.5em;
  padding-bottom: 0.89em;

  @include mobile {
    font-size: 1em;
  }
}

.formItem {
  width: 100%;
}

.formInput {
  width: 100%;
  display: block;
  border-radius: 0.25em;
  border: var(--border-tertiary);
  font-size: 1.125em;
  font-family: 'Lato Regular';
  padding: 0.75em;
  line-height: 1.5em;
  background: var(--input-bg-secondary);
  color: var(--app-color);
}

.inputSpec {
  display: block;
  font-size: 0.875em;
  line-height: 1.5em;
  color: var(--sub-title-color);
  padding-top: 0.25em;

  @include mobile {
    font-size: 0.75em;
  }
}

.giftInfo {
  display: flex;
  align-items: center;
  padding-top: 0.5em;
  gap: 1.5em;

  @include mobile {
    padding-top: 0em;
  }
}

.logoImg {
  display: flex;
  align-items: center;
  background: #FFF;
  border: var(--border-tertiary);
  border-radius: 0.25em;
  max-width: 7.5em;
  min-height: 4.5em;

  img {
    width: 100%;
  }

  @include mobile {
    max-width: 6.25em;
    min-height: 3.75em;
  }
}

.giftName {
  font-weight: 600;
  padding-bottom: 0.25em;
  font-size: 1.125em;
  line-height: 1.5em;

  @include mobile {
    font-size: 0.875em;
  }
}

.giftCountry {
  color: var(--label-color);
  font-weight: 600;
  font-size: 0.875em;
  line-height: 1.5em;

  @include mobile {
    font-size: 0.75em;
  }
}

.divLine {
  width: 100%;
  height: 0px;
  border-top: var(--border-tertiary);
  border-width: 0.03125em;
}

.total {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.totalLabel {
  font-weight: 600;
  font-size: 0.875em;
  line-height: 1.286em;
}
