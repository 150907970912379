@import '../../styles';

.component {
  position: relative;
  display: flex;
  margin-left: 0.375em;
}

.info {
  width: 1em;
  height: 1em;

  path {
    fill: #555A69;
  }
}

.content {
  position: absolute;
  bottom: 3em;
  right: -2em;
  max-width: 24em;
  width: max-content;
  background: #101629;
  color: #FFFBF6;
  opacity: 0.9;
  text-align: left;
  font-size: 0.75em;
  line-height: 1.5em;
  padding: 0.875em 1em;
  border-radius: 0.375em;
  font-weight: 500;

  &::after {
    position: absolute;
    bottom: -3.5em;
    right: 2.25em;
    content: '';
    border-width: 2em 0.5em;
    border-style: solid;
    opacity: 0.9;
    border-color: #101629 transparent transparent transparent;
  }

  @include mobile {
    max-width: 15em;
  }
}
