@import '../../styles';

.container {
  width: 100%;
}

.input_wrapper {
  position: relative;
  width: 100%;
}

.input {
  width: 100%;
  height: 3.125em;
  border-radius: .25em;
  border: var(--border-tertiary);
  padding: 0 .75em;
  font-size: 1.125em;
  background-color: #FFF;
}

.input_error, .input_error:focus {
  border: 0.1em solid #F65166;
  border-radius: 0;
  outline: none;
}

.input_adjust {
  position: absolute;
  top: .9em;
  right: .75em;
  display: flex;
  align-items: center;
}

.adjust_slightly {
  display: flex;
  flex-direction: column;
  padding-left: .5em;
}

.error {
  display: flex;
  padding: 1em;
  background-color: #FDDCE0;
  color: #101629;
}

.error_icon {
  fill: #101629;
}

.error_text {
  font-size: .9em;
  font-family: 'Lato Regular';
  padding-left: .75em;
}

.mint_button {
  margin-top: 1.35em;
}

.desc {
  display: block;
  text-align: center;
  margin-top: 1em;
}




/* Success */
.success {
  position: relative;
  max-width: 30em;
  display: flex;
  flex-direction: column;
  background-color: var(--card-bg);
  border-radius: 0.5em;
  padding: 0 1.875em 1.875em;
  font-family: 'Lato Regular';

  @include mobile {
    padding: 0 1em 1em;
  }
}

.images {
  display: flex;
  justify-content: center;
  margin: -9% 2.5em 0 3.5em;

  @include mobile {
    margin: -3vh 1em;
  }
}

.img {
  flex: 1;
  margin: 0 -.5em;
  z-index: 3;
  max-width: 8em;

  &_mg {
    margin: 0 .75em;
  }

  &_anticlockwise {
    transform: rotate(-7deg);
    margin-top: -1vh;
    z-index: 2;
  }
  
  &_clockwise {
    transform: rotate(7deg);
    margin-top: -1vh;
    z-index: 2;
  }

  img {
    width: 100%;
    border-radius: 0.875em;
  }

  @include mobile {
    margin: 0 -.25em;
  
    &_mg {
      margin: 0 2vw;
    }
  
    img {
      border-radius: .5em;
    }
  
    &_anticlockwise {
      margin-top: -.5em;
      z-index: 2;
    }

    &_clockwise {
      margin-top: -.5em;
    }
  }
}

.remaining {
  flex: 1.05;
  position: relative;
  z-index: 1;
  width: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: -1.5em;
  margin-bottom: .75em;

  img {
    width: 100%;
  }

  &_text {
    position: absolute;
    color: #FFF;
    font-size: 1.375em;
    transform: rotate(-7.74deg);
    padding-left: .5em;
  }

  @include mobile {
    margin-left: -1em;

    &_text {
      font-size: 1em;
    }
  }
}

.success_title {
  font-family: 'FredokaOne';
  font-size: 2em;
  text-align: center;
  padding-top: 1em;

  @include mobile {
    font-size: 1.75em;
    padding-top: 2em;
  }
}

.success_description {
  font-size: 1.375em;
  line-height: 1.5em;
  text-align: center;
  margin: .5em 0;

  @include mobile {
    font-size: 1.125em;
  }
}

.success_explore {
  font-size: 1.25em;
  color: var(--sub-title-color);
  margin-bottom: 1.25em;
  text-align: center;

  a {
    text-decoration: underline;
    color: var(--sub-title-color);
  }

  @include mobile {
    .success_explore {
      font-size: 1.125em;
    }
  }
}

.button {
  margin-top: 1em;
}
