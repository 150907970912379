@import "../../styles";

.footer_container {
  padding-left: 15%;
  padding-right: 15%;
  background-color: var(--footer-bg);
  @include mobile {
    padding-left: 0;
    padding-right: 0;
    padding-top: 2em;
  }
}
.footer {
  display: grid;
  grid-template-columns: 50% 50%;
  padding-top: 2em;
  padding-bottom: 2em;
  @include mobile {
    display: flex;
    flex-direction: column;
    gap: 0;
  }
}
.footer_bottom {
  grid-column-start: 1;
  grid-column-end: 3;
  margin: 2em;
  padding-left: 1em;
  padding-right: 1em;
  @include mobile {
    margin: 0;
    margin-bottom: 2em;
  }
}

.footer_left {
  margin: 2em;
  padding-left: 1em;
  padding-right: 1em;
  @include mobile {
    padding-left: 1em;
    padding-right: 1em;
    margin: 0;
  }
}

.footer_left_text_header {
  font-family: "Barlow Bold";
  font-size: 1.25em;
}
.footer_left_text_content {
  font-family: "Lato Regular";
  font-size: 1em;
  margin: 1em 0;
  line-height: 1.5em;
}
.footer_left_text_footer {
  font-family: "Lato Regular";
  font-size: 0.75em;
  margin: 1em 0;
  line-height: 1.5em;
}

.footer_bar {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 6em;
  background-color: #f94f01;

  @include mobile {
    height: 4em;
  }

  @include tablet {
    height: 5em;
  }
}

.footer_logo {
  height: 5.5em;

  @include mobile {
    height: 3.875em;
  }

  @include tablet {
    height: 4.5em;
  }
}

.footer_right_content {
  font-size: 1em;
  font-family: "Lato Regular";
  margin: 1em 0;
  line-height: 1.75em;
}
.footer_right {
  margin: 2em;
  padding-left: 1em;
  padding-right: 1em;
  font-size: 1em;
  font-family: "Lato";
  display: grid;
  grid-template-columns: 50% 50%;
  @include mobile {
    margin: 0;
  }
  @include tablet {
    display: flex;
    flex-direction: column;
    gap: 0;
  }
}
.footer_right a {
  text-decoration: none;
  color: inherit;
}

.footer_text {
  font-size: 1.25em;
  font-family: "Lato Regular";
  padding-top: 3em;

  @include mobile {
    font-size: 0.875em;
    padding-top: 2em;
  }

  @include tablet {
    font-size: 1.125em;
    padding-top: 2.75em;
  }
}

.footer_community {
  display: flex;
  align-items: center;
  padding: 0.5em 0 3em;

  &_label {
    font-size: 0.75em;
    font-family: "Lato BoldItalic";
    margin: 0 0.5em;
  }

  img {
    width: 4.75em;
    height: 3.85em;
    margin-bottom: -1em;
  }

  a {
    display: flex;
    margin: 0.75em;
    cursor: pointer;
  }

  @include mobile {
    padding-bottom: 1.75em;

    img {
      width: 3em;
      height: 2.45em;
      margin-bottom: -0.5em;
    }

    a {
      margin: 0.5em;
    }
  }

  @include tablet {
    img {
      width: 4em;
    }

    a {
      margin: 0.5em;
    }
  }
}
