@import "../../styles";

.component {
  position: relative;
  max-width: 10.75em;

  @include mobile {
    max-width: 12em;
  }
}

.img {
  position: relative;
  overflow: hidden;
  border-radius: 0.25em;
  width: 100%;
  display: flex;

  img {
    width: 100%;
    aspect-ratio: 1.8 / 1;
  }

  &_selected {
    border: 0.125em solid var(--border-color-secondary);
  }
}

.duration {
  position: absolute;
  display: flex;
  align-items: center;
  gap: 0.25em;
  padding: 0.25em;
  bottom: 0;
  right: 0.25em;

  span {
    font-size: 0.5em;
    font-weight: 600;
    color: #B04700;
  }
}

.name {
  font-size: 0.75em;
  font-weight: 600;
  padding: 0.25em 0;
}

.part {
  color: var(--highlight-color);
  font-size: 0.625em;
  font-weight: 600;
  line-height: 1.5em;
}

