@import '../../styles';

.container {
  position: fixed;
  right: 0;
  bottom: 0;
  max-height: 43em;
  padding: 0 .5em;
  z-index: $z-index-warning;
  overflow-y: auto;
  scroll-behavior: smooth;

  @include mobile {
    top: auto;
    bottom: 0;
    left: 0;
    right: 0;
  }

  @include tablet {
    top: auto;
    bottom: 0;
    left: 0;
    right: 0;
  }
}

/* Warning messages */
.messages {
  position: relative;
  display: flex;
  justify-content: space-between;
  margin: .5em 0;
  padding: .75em;
  background-color: var(--message-bg);
  border: var(--border-tertiary);
  border-radius: .25em;
}

.left_side {
  display: flex;
}

.content {
  max-width: 20em;
  display: flex;
  flex-direction: column;
  font-family: 'Lato Regular';
  line-height: 1.25em;
  margin: 0 1em;

  @include mobile {
    font-size: .9em;
  }
}

.title {
  font-family: 'Barlow Bold';
  font-size: .9em;
  margin-bottom: .375em;
}

.icon {
  width: 1.75em;
  height: 1.75em;
  margin-top: .35em;
}

.cancel {
  cursor: pointer;
  margin-top: .35em;
  fill: var(--svg-fill-tertiary);
}
