@import '../../styles';

.container {
  width: 100%;
  border-radius: 0.25em;
}
  
.empty_message {
  font-size: 1.125em;
  color: #293250;
  font-family: "Lato Regular";
}

.table {
  display: grid;
  grid-template-columns: 3fr 1fr 1fr;

  @include mobile {
    grid-template-columns: 2fr 1fr 0.5fr;
  }
}

.th {
  font-size: 0.9em;
  font-family: 'Barlow Regular';
  line-height: 2em;
  text-transform: uppercase;
  margin-bottom: .5em;

  &:first-child {
    padding-left: 1.5em;
  }

  &:last-child {
    padding-left: 1.5em;
  }
}

.tr {
  grid-column: 1 / -1;
  display: grid;
  grid-template-columns: 3fr 1fr 1fr;
  padding: .625em 1.5em;

  @include mobile {
    grid-template-columns: 2fr 1fr 0.5fr;
  }
}

.td {
  font-size: 1.125em;
  font-family: 'Barlow Regular';
  line-height: 2em;
  display: flex;
  align-items: center;
}

.bold_text {
  font-weight: bold;
}

.icon {
  width: 2em;
  height: 2em;
  border-radius: 50%;
  margin-right: 1em;

  @include mobile {
    width: 1.5em;
    height: 1.5em;
  }
}

.address {
  @include mobile {
    display: none;
  }
}

.address_mobile {
  display: none;

  @include mobile {
    display: block;
  }
}

.controls_wrap {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.offer_button { 
  height: 2.5em;
  width: 100%;
  border: var(--button-border);
  border-width: 0.0625em;
  border-radius: .5em;
  font-size: 0.875em;
  font-family: 'Lato Bold';
  color: var(--svg-fill-primary);
  background-color: var(--button-bg-secondary);
  cursor: pointer;
  padding: 0.375em 0;
  line-height: 1.25em;

  @include mobile {
    display: none;
  }
}

.edit_button_wrap {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  position: relative;

  @include mobile {
    display: none;
  }
}

.edit_button {
  height: 2.5em;
  width: 5.5em;
  border: var(--button-border);
  border-radius: .5em 0 0 .5em;
  font-size: 0.875em;
  font-family: 'Lato Bold';
  color: var(--svg-fill-primary);
  background-color: var(--button-bg-secondary);
  cursor: pointer;
  padding: 0.375em 0;
  line-height: 1.25em;
  border-right: 0;

  @include mobile {
    margin-top: 0.5em;
  }
}

.edit_button_mobile {
  width: 2em;
  height: 2em;
  border: var(--button-border);
  border-width: 0.0625em;
  background-color: transparent;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  display: none;

  svg {
    width: 0.625em;
    fill: var(--svg-fill-primary);

    path {
      fill: var(--svg-fill-primary);
    }
  }

  @include mobile {
    display: flex;
  }
}

.edit_dropdown_button {
  border-right: var(--button-border);
  border-radius: 0 .5em .5em 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1.8em;

  &:focus {
    background: var(--button-dropdown-bg-hover);
    &+ul {
      display: block;
    }
  }

  svg {
    width: 0.625em;
    fill: var(--svg-fill-primary);
  }
}

.edit_dropdown_content {
  position: absolute;
  top: 1.25em;
  z-index: 1;
  min-width: 8em;
  list-style-type: none;
  padding: 0;
  display: none;
  border-radius: 0.25em;
  background-color: var(--dropdown-bg);
}

.edit_dropdown_item {
  font-size: 0.875em;
  line-height: 1.25em;
  font-family: "Lato Regular";
  padding: .5em 1em;
  border: var(--border-tertiary);
  cursor: pointer;
  border-radius: .25em;
  &:hover {
    background-color: var(--button-dropdown-bg-hover);
  }
}

.bottom_sheet_wrap {
  transition: opacity 500ms;
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 998;
  width: 100%;
  height: 100%;
}

.bottom_sheet_block_layer {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: rgba(16, 22, 41, 0.4);
}

.bottom_sheet {
  position: absolute;
  bottom: 0;
  z-index: 999;
  width: 100%;
  background-color: var(--table-collpased-bg);
  padding: 1em;
  padding-left: inherit;
  padding-right: inherit;
  font-family: 'Lato Regular';
}

.bottom_sheet_header {
  text-align: center;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 1em;
  margin-bottom: 1.5em;
  font-size: 1.375em;

  svg {
    position: absolute;
    left: 1em;
    fill: var(--svg-fill-tertiary);
  }
}

.bottom_sheet_list_menu {
  padding: 0 1em;

  svg {
    path {
      fill: var(--svg-fill-tertiary);
    }
  }
}

.bottm_sheet_menu_item {
  font-size: 1.125em;
  margin: 1.5em 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  span {
    margin-left: 0.5em;
  }

  &:last-child {
    margin-bottom: 0;
  }
}

.empty_messagge {
  padding: 0 1.5em;
}