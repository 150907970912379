@import '../../styles';

.container {
  background-color: transparent;
  width: 100%;
}


.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1em 0;
}

.label {
  font-size: 1.125em;
  font-family: 'Lato Regular';
}

.icon {
  cursor: pointer;
  transition: all 0.2s ease-out;
  
  &_closed {
    transform: rotate(180deg);
  }
}

.content {
  transition: max-height 50ms ease-in-out 0ms;

  &_opened {
    max-height: 800em;
    padding: 1em 0;
  }
  
  &_closed {
    max-height: 0;
    overflow: hidden;
  }  
}
