@import '../../styles';

.container {
  max-width: 67vw;
  margin: auto;
  padding-bottom: 4em;

  @include mobile {
    max-width: 100vw;
    padding: 0 1em 4em;

  }
}

.back {
  padding: 2em 0;
  display: flex;
  align-items: center;
  gap: 0.5em;
  font-weight: 600;
  width: fit-content;
  color: inherit;

  svg {
    margin-right: 0.75em;
    fill: var(--svg-fill-tertiary);
  }
}

.title {
  font-size: 2.5em;
  font-family: 'FredokaOne';
  letter-spacing: -0.8px;
  line-height: 1.5em;

  @include mobile {
    font-size: 1.75em;
  }
}

.loading {
  width: 100%;
  font-size: 1.5em;
  text-align: center;
}

.body {
  width: 100%;
  position: relative;
  padding-top: 2em;
}

.scroll_wrap {
  position: relative;
  width: 100%;
  padding-top: 1.5em;
}

.scroll_frames_wrap {
  position: relative;
  overflow-x: auto;
  overflow-y: hidden;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  &_active {
    cursor: grabbing;
  }
}

.scroll_icon {
  position: absolute;
  top: calc(50% - 0.625em);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background: #FFEFDDDE;
  width: 1.75em;
  height: 1.75em;
  border-radius: 50%;

  svg {
    fill: var(--svg-fill-primary);
  }

  &_prev {
    left: 0.75em;

    svg {
      transform: rotate(90deg);
    }
  }

  &_next {
    right: 0.75em;

    svg {
      transform: rotate(-90deg);
    }
  }

  @include mobile {
    display: none;
  }
}

.scroll_frames {
  display: flex;
  align-items: baseline;
  gap: 1em;
  width: fit-content;
}

.scroll_frame {
  width: 10.75em;
  cursor: pointer;

  @include mobile {
    width: 7.75em;
  }
}
