@import '../../styles';

.container {
  font-family: Lato Regular;

  @include mobile {
    padding: 0 1em 2em;
  }
}

.header {
  // background: var(--page-header-bg);
  position: relative;
  overflow: hidden;
  z-index: $z-index-layer2;
  display: flex;
  align-items: center;
  justify-content: center;
}

.headerContent {
  position: relative;
  max-width: 60em;
  min-width: 35em;
  padding: 5em 0em 5em 2em;
  z-index: calc($z-index-layer2 + 1);

  @include mobile {
    padding: 23em 1em 1.5em;
    min-width: 100%;
  }
}

.title {
  font-family: 'FredokaOne';
  font-size: 3em;
  letter-spacing: -0.02em;
  line-height: 1.25em;
  max-width: 8em;
  display: flex;

  @include mobile {
    font-size: 1.75em;
    line-height: 1.5em;
  }
}

.season {
  font-family: 'FredokaOne';
  font-size: 1.375em;
  letter-spacing: -0.02em;
  line-height: 1.5em;
  margin: 2em 0 0.75em;

  @include mobile {
    font-size: 1.125em;
    margin: 1.5em 0 0.5em;
  }
}


.seasonWait {
  font-size: 1.125em;
  line-height: 1.5em;

  @include mobile {
    font-size: 0.75em;
  }
}

.seasonPeriod {
  font-size: 1.125em;
  line-height: 1.5em;
  white-space: pre-wrap;

  span {
    font-weight: 700;
  }

  @include mobile {
    font-size: 0.75em;
  }
}

.bananaGift {
  position: relative;
  max-width: 12em;
  margin-left: -4em;
  margin-right: 2em;

  @include tablet {
    display: none;
  }

  @include mobile {
    position: absolute;
    top: 0;
    left: 0;
    margin: 0;
  }
}

.bannerGift {
  display: flex;
  position: relative;
  max-width: 12em;
  transform: matrix(-1, 0, 0, 1, 0, 0);

  @include mobile {
    position: relative;
    max-width: 30vw;
  }
}

.bannerBanana {
  display: flex;
  position: relative;
  max-width: 6em;
  margin-bottom: -4em;

  @include mobile {
    max-width: 14vw;
    margin-bottom: -2em;
  }
}

.bannerBananaOpen {
  display: flex;
  position: relative;
  max-width: 6em;
  margin-left: 4em;
  margin-bottom: -1em;

  @include mobile {
    max-width: 14vw;
    margin-left: 2.5em;
    margin-bottom: 0em;
  }
}

.stallScene {
  display: flex;
  align-items: flex-end;
  position: relative;
  max-width: 36vw;

  @include mobile {
    position: absolute;
    justify-content: center;
    top: 8em;
  }
}

.giftsInstall {
  position: relative;
}

.bannerGifts {
  display: flex;
  position: absolute;
  right: 0em;
  bottom: 3em;
  max-width: 12em;

  @include mobile {
    max-width: 27vw;
    right: -0.25em;
    bottom: 2em;
  }
}

.bannerStall {
  display: flex;
  position: relative;
  right: 0.625em;
  bottom: 0.625em;
  max-width: 23em;

  @include mobile {
    max-width: 58vw;
    right: 0em;
    bottom: 0em;
  }
}

.bannerBananaChimp {
  display: flex;
  position: relative;
  margin-right: -5em;
  max-width: 20em;

  @include mobile {
    max-width: 48vw;
    margin-right: -3em;
  }
}

.body {
  max-width: 60em;
  width: 100%;
  margin: auto;
  padding: 4em 0;

  @include mobile {
    padding: 1.5em 0;
  }
}

.summaryBar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.5em 2em;
  border: 0.125em solid #101629;
  box-shadow: 0em 0.25em 0em #101629;
  border-radius: 0.75em;
  margin-bottom: 4em;
  background: var(--section-bg);

  @include mobile {
    margin-bottom: 1.5em;
    flex-direction: column;
    align-items: flex-start;
  }
}

.summaryData {
  display: flex;
  align-items: stretch;
  gap: 4em;

  &_inline {
    width: 100%;
    justify-content: space-evenly;
  }

  @include mobile {
    justify-content: space-between;
    gap: 1em;
    width: 100%;
  }
}

.dataCol {
  display: flex;
  flex-direction: column;
}

.dataLabel {
  font-weight: 600;
  font-size: 0.875em;
  line-height: 2em;
  color: var(--label-color);
  margin-bottom: 0.25em;

  @include mobile {
    font-size: 0.75em;
    line-height: 1.5em;
  }
}

.dataValue {
  display: flex;
  align-items: center;
  font-size: 1.125em;
  height: 100%;

  @include mobile {
    font-size: 1em;
  }
}

.bananasOwned {
  font-size: 1.375em;

  @include mobile {
    font-size: 1.125em;
  }
}

.selectCol {
  width: 12.5em;

  @include mobile {
    width: 100%;
    margin-top: 1.5em;
  }
}

.loading {
  text-align: center;
  padding: 3em 0;
  font-weight: 600;
}
