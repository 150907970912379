@import "../../styles";

.container {
  max-width: 56em;
  margin: auto;
  padding: 2em;
  font-family: "Lato Regular";

  @include mobile {
    padding: 2em 1em;
  }
}

.heading {
  font-family: "FredokaOne";

  @include mobile {
    margin-bottom: 1.5em;
  }
}

.wrapper {
  background: transparent;
  display: flex;
  align-items: flex-start;
  column-gap: 2em;

  @include mobile {
    flex-direction: column;
    column-gap: 0;
  }
}

.info_wrapper {
  flex: 1;
  background-color: transparent;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.detail_wrapper {
  flex: 1;
  background-color: var(--card-bg);
  border-radius: 0.75em;
  width: 100%;
}

.info_panel {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 2em;
  background-color: var(--card-bg);
  padding: 2.5em 1.5em;
  border-radius: 0.75em;
  font-size: 1.125em;

  @include mobile {
    padding: 2em 1.5em;
    margin-bottom: 1.5em;
  }
}

.info_title {
  font-size: 1.375em;
  line-height: 0.5em;
  margin-bottom: 2em;
  font-weight: 600;

  @include mobile {
    font-size: 1.25em;
    line-height: 1.875em;
    margin-bottom: 1em;
  }
}

.detail_content {
  padding: 2.5em 1.5em;
}

.status {
  font-size: 1.375em;
  font-weight: 700;
  display: flex;
  align-items: center;

  svg {
    width: 1.25em;
    height: 1.25em;
    margin-right: 0.5em;
  }

  @include mobile {
    font-size: 1.25em;
    line-height: 1.875em;
    margin-bottom: 1em;
  }
}

.status_shipped {
  margin-bottom: 2em;

  @include mobile {
    margin-bottom: 1em;
  }
}

.field_text {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 1em;

  &:last-child {
    margin-bottom: 0;
  }
}

.field_value,
.receiver {
  font-weight: 700;
}

.field_value_href {
  font-weight: 700;
  color: #F94F01;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
}

.email {
  margin-top: 1em;
}

.btn_group {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  position: relative;
}

.primary_btn {
  font-size: 0.875em;
  width: 9em;
  height: 2.5em;
  margin: 2em 1em 0 0;

  @include mobile {
    margin-top: 1em;
  }
}
