@keyframes logoShow {
  0% {
    top: 5em;
    transform: scale(0.8, 0.8);
  }

  50% {
    top: 5em;
    transform: scale(0.8, 0.8);
  }

  80% {
    transform: scale(1, 1);
    top: 1em;
  }

  88% {
    transform: scale(0.95, 0.95);
    top: 1.75em;
  }

  94% {
    transform: scale(1, 1);
    top: 1em;
  }

  97% {
    transform: scale(0.975, 0.975);
    top: 1.25em;
  }

  100% {
    transform: scale(1, 1);
    top: 1em;
  }
}

@keyframes logoBounce {
  0% {
    transform: scale(1, 1);
    top: 1em;
  }

  12% {
    transform: scale(0.8, 0.8);
    top: 5em;
  }

  16% {
    transform: scale(0.8, 0.8);
    top: 5em;
  }

  18% {
    transform: scale(1, 1);
    top: 1em;
  }

  20% {
    transform: scale(0.95, 0.95);
    top: 1.75em;
  }

  22% {
    transform: scale(1, 1);
    top: 1em;
  }

  24% {
    transform: scale(0.975, 0.975);
    top: 1.25em;
  }

  25% {
    transform: scale(1, 1);
    top: 1em;
  }
}

@keyframes steve1Show {
  0% {
    bottom: -80%;
  }

  40% {
    bottom: -4em;
  }

  100% {
    bottom: -5.5em;
  }
}

@keyframes steve2Vanish {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
    display: none;
  }
}

@keyframes leaf1Rotate {
  0% {
    transform: rotate(0);
  }

  40% {
    transform: rotate(2deg);
  }

  60% {
    transform: rotate(2deg);
  }

  100% {
    transform: rotate(0);
  }
}

@keyframes leaf1Vanish {
  0% {
    transform: translate(0, 0);
  }

  100% {
    transform: translate(-80%, 100%);
    display: none;
  }
}

@keyframes leaf2Rotate {
  0% {
    transform: rotate(0);
  }

  40% {
    transform: rotate(-2deg);
  }

  60% {
    transform: rotate(-2deg);
  }

  100% {
    transform: rotate(0);
  }
}

@keyframes leaf2Vanish {
  0% {
    transform: translate(0, 0);
  }

  100% {
    transform: translate(80%, 100%);
    display: none;
  }
}

