@import '../../styles';

.container {
  position: relative;
  border-radius: 0.75em;
  width: fit-content;
  margin: auto;
  font-family: 'Lato Regular';
  overflow: auto;
  max-height: inherit;
  background: var(--app-bg);

  @include mobile {
    width: 100%;
  }
}

.close {
  position: absolute;
  top: 1.75em;
  right: 1.75em;
  fill : var(--svg-fill-tertiary);

  @include mobile {
    top: 1.25em;
    right: 1.25em;
  }
}

.preClaim {
  max-width: 29em;
  margin: auto;
}

.preHeader {
  display: flex;
  align-items: stretch;
  gap: 1em;
  padding: 4em 1.5em 1.5em;
}

.logoImg {
  display: flex;
  align-items: center;
  background: #FFF;
  border: var(--border-tertiary);
  border-radius: 0.25em;
  max-width: 9em;

  img {
    width: 100%;
  }
}

.giftName {
  font-weight: 600;
  font-size: 1.25em;
  line-height: 1.4em;
  padding-bottom: 0.25em;
}

.giftCountry {
  color: var(--label-color);
  font-weight: 600;
  font-size: 0.75em;
  line-height: 1.75em;
}

.preBody {
  background: var(--modal-body-bg);
  padding: 1.5em;
}

.parag {
  font-size: 0.75em;
  line-height: 1.5em;
  color: var(--label-color);
  margin: 0 0 1.5em;

  a {
    color: #385AE1;
  }
}

.blockTip {
  margin-bottom: 1em;
}

.specLabel {
  display: block;
  color: #B04700;
  font-weight: 600;
}

.confirmingWrap {
  text-align: center;
  min-width: 25em;
  padding: 1.5em 1.5em 2.5em;

  @include mobile {
    min-width: unset;
  }
}

.confirmingImg {
  max-width: 13.375em;
}

.confirmingTitle {
  font-size: 1.375em;
  font-family: 'Lato Bold';
  line-height: 1.5em;
}

.confirmingSubTitle {
  font-size: 0.875em;
  line-height: 1.25em;
  color: var(--sub-title-color);
  margin-top: 0.5em;
}
