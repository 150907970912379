@import "../../styles";

.container {
  max-width: 954px;
  padding: 4em 0;
  margin: auto;

  @include mobile {
    padding: 3.5em 1.5em;
  }
}

.title {
  font-family: "FredokaOne";
  font-size: 2.5em;
  line-height: 1.5em;
  padding-bottom: 0.6em;

  @include mobile {
    font-size: 2em;
    padding-bottom: 0.5em;
  }
}

.content {
  font-family: "Lato Regular";
  font-size: 1.125em;
  line-height: 1.5em;
}

.p {
  &_level2 {
    padding-left: 2em;
  }
}
