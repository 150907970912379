@import "../../styles";

.container {
  max-width: 60em;
  margin: auto;
  padding: 2em;
  font-family: "Lato Regular";

  @include mobile {
    padding: 0;
  }
}

.back {
  display: flex;
  align-items: center;
  width: fit-content;

  svg {
    fill: var(--svg-fill-tertiary);
  }

  @include mobile {
    display: none;
  }
}

.back_label {
  font-size: 1.125em;
  font-family: 'Barlow SemiBold';
  text-decoration-line: underline;
  padding-left: 1em;
  cursor: pointer;
}

.product_wrap {
  display: flex;
  gap: 4em;
  margin-top: 2em;
  position: relative;

  @include mobile {
    flex-direction: column;
    gap: 0;
    margin-top: 0;
  }
}

.product_image {
  display: block;
  flex: 1.2;
}

.product_image_box {
  display: none;

  @include mobile {
    display: block;
  }
}

.product_image_item {
  cursor: pointer;
  display: flex;

  img {
    width: 100%;
  }

  @include mobile {
    justify-content: center;
    align-items: center;
  }
}

.slider {
  padding-left: 0.5em;
  div {
    &:focus {
      outline: none;
    }
  }
}

.slide_image {
  background-color: #ffffff;
  width: 29vw !important;
  padding: 1em;

  img {
    width: 100%;
    margin-top: 1em;
  }
}

.product_detail {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex: 1;
  gap: 1.5em;

  @include mobile {
    padding: 2em 1.5em;
  }
}

.product_name {
  font-size: 2.75em;
  font-weight: 600;

  @include mobile {
    font-size: 1.5em;
  }
}

.product_price {
  line-height: 1.5em;
  font-size: 1.25em;
  font-family: 'Lato Black';
  margin-top: 1em;
}

.form_label {
  font-size: 1.25em;
  line-height: 1.4em;
  display: flex;
  align-items: center;
  margin-bottom: 0.5em;
}

.form_warning {
  display: block;
  line-height: 1.5em;
  margin-top: 0.25em;

  &_red {
    color: #F65166;
  }
}

.product_quantity {
  display: flex;
  flex-direction: column;
}

.quantity_input {
  background-color: #FFF;
  border: var(--border-tertiary);
  border-radius: 0.25em;
  height: 3.11em;
  padding: 0.5em 0.75em;
  width: 100%;
  font-size: 1.125em;

  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    opacity: 1;
    cursor: pointer;
  }

  &_invalid {
    border-color: #F65166;
  }
}

.product_size {
  display: flex;
  flex-direction: column;
}

.product_size_options {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 0.5em;
}

.size_tab {
  font-size: 1.125em;
  line-height: 1.5em;
  color: var(--button-color-secondary);
  border: var(--button-border);
  border-radius: 0.5em;
  flex: 1;
  padding: 0.375em 0.5em;
  display: flex;
  justify-content: center;
  cursor: pointer;

  &:not(&_active,&_disabled) {
    &:hover {
      background-color: var(--button-bg-secondary-hover);
    }
  }

  &_active {
    background-color: var(--button-bg-primary);
    color: #FFF;
  }

  &_disabled {
    background-color: var(--button-bg-disabled);
    color: var(--button-color-disabled);
    border-color: transparent;
    cursor: default;
  }
}

.payment_dropdown {
  height: 3.5em;
}

.measurements {
  margin-top: 0.5em;

  table {
    border-collapse: collapse;
    width: 100%;
    table-layout: fixed;
  }
  
  th, td {
    border: 0.0625em solid #B1A699;
    height: 2.5em;
  }

  th {
    background: var(--table-bg2);
    font-weight: 400;
  }

  td {
    &:first-child {
      background: var(--table-bg2);
    }
    text-align: center;
  }
}

.measurements_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 0.75em;
}

.measurements_switch {
  display: table;
  border: var(--button-border);
  border-radius: 0.25em;
}

.switch_tab {
  display: table-cell;
  font-size: 1.125em;
  width: 4em;
  height: 2em;
  text-align: center;
  vertical-align: middle;
  color: var(--title-color);
  cursor: pointer;

  &:not(:first-child) {
    border-left: var(--button-border);
  }

  &_active {
    background: var(--button-bg-secondary-hover);
  }
}
