@import "../../styles";

.list {
  overflow: auto;
  display: flex;
  flex-direction: column;
  gap: 2em;

  @include mobile {
    gap: 1.5em;
  }
}

.empty {
  display: block;
  font-size: 1.25em;
  font-family: "Lato Bold";
  text-align: center;
  padding: 2em;
}

.item {
  display: flex;
  gap: 1.5em;

  @include mobile {
    gap: 1em;
  }
}

.image {
  width: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    max-height: 6.375em;
  }
}

.details {
  width: 100%;
}

.item_row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  font-size: 1.125em;
  line-height: 1.5em;
  margin-bottom: 1em;

  @include mobile {
    font-size: 1em;
  }
}

.size {
  font-size: 0.875em;
  line-height: 1.5em;
  margin-bottom: 0.25em;
}

.quantity {
  display: flex;
  align-items: center;
}

.quantity_value {
  font-size: 0.875em;
  line-height: 1.5em;
}

.quantity_mutation {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 1em;
  gap: 0.5em;
}

.quantity_mutation_button {
  font-size: 1.125em;
  color: var(--button-color-secondary);
  border: var(--button-border);
  border-radius: 0.375em;
  width: 1.25em;
  height: 1.25em;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &_disabled {
    color: var(--button-color-disabled);
    border-color: var(--button-color-disabled);
    cursor: default;
  }

  &:not(&_disabled) {
    &:hover {
      background-color: var(--button-bg-primary);
      color: #FFF;
    }
  }
}
