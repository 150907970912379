@import "../../styles";

.container {
  width: 100%;
}

.title {
  font-size: 1.375em;
  line-height: 0.5em;
  margin-bottom: 1em;
  font-weight: 600;
}

.pricing_message {
  color: #555a69;
}

.shipping {
  border-top: var(--border-tertiary);
  padding: 1em 0 0.5em;
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  font-size: 1.25em;
}

.order_total {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  padding: 1em 0 1.5em;
  font-size: 1.375em;
  font-weight: 700;
}

.order_total_value {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  line-height: 1.5em;
}

.order_list {
  margin: 3.5em 0;
  font-family: "Lato Regular";

  @include mobile {
    margin: 2.5em 0;
  }
}
