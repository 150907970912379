@import '../../styles';

.container {
  max-width: 34em;
  margin: 15vh auto 0;

  @include mobile {
    padding: 0 .875em;
    margin: 4em auto 0;
  }
}

.bar {
  display: none;
  
  @include mobile {
    width: 100%;
    display: block;
    padding: 1em;
  }
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: var(--card-bg);
  border-radius: .5em;
  padding: 2.5em;
  font-family: 'Lato Regular';

  @include mobile {
    padding: 1.75em .75em;
  }
}

.title {
  display: block;
  width: 100%;
  font-size: 2em;
  font-family: 'FredokaOne';
  margin-bottom: .75em;
}

.pool {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1.125em;
  padding: 1.5em;
  margin-bottom: 1.34em;
  background: var(--strip-bar-bg);
  border-radius: .25em;
}

.remark {
  text-align: center;
  font-size: .875em;
  line-height: 1em;
  margin-top: 2em;
}




.run_chimp {
  position: absolute;
  left: 5vw;
  bottom: 5vh;
  max-width: 20em;


  @include mobile {
    display: none;
  }

  @include tablet {
    display: none;
  }
}

.spatter_one {
  position: absolute;
  top: 0;
  right: 0;
  
  @include mobile {
    display: none;
  }

  @include tablet {
    display: none;
  }
}

.spatter_two {
  position: absolute;
  top: 15vh;
  right: 5vh;

  @include mobile {
    display: none;
  }

  @include tablet {
    display: none;
  }
}

