@import '../../styles';

.container {
  padding: 1.5em;
  background: var(--card-bg);
  border-radius: 0.375em;
  display: flex;
  flex-direction: column;
  row-gap: 1.5em;
  align-items: center;
  @include mobile {
    padding: 1em;
  }
}

.header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.items{
  display: flex;
  justify-content: center;
  column-gap: 2em;
  @include mobile {
    flex-direction: column;
    row-gap: 2em;
  }
}

.item{
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  row-gap: 1em;
}

.button{
  background-color: var(--button-bg-primary);
  width: 400px;
  color: var(--form-bg);
  text-align: center;
  text-decoration: none;
  padding: 10px;
  border-radius: 10px;

  @include mobile {
    width: 300px;
  }
}