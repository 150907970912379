@import '../../styles';

.container {
  font-family: 'Lato Regular';
}

.header {
  background: var(--page-header-bg);
  position: relative;
  overflow: hidden;
}

.headerInner {
  max-width: 60em;
  margin: auto;
  padding: 7em 0;

  @include mobile {
    padding: 2em 1em;
  }
}

.title {
  font-family: 'FredokaOne';
  font-size: 3em;
  line-height: 1.25em;
  letter-spacing: -0.02em;

  @include mobile {
    font-size: 1.75em;
  }
}

.sortTabs {
  display: flex;
  align-items: center;
  gap: 1em;
  margin-top: 2em;
  flex-wrap: wrap;

  @include mobile {
    font-size: 0.875em;
    margin-top: 1.5em;
    gap: 0.75em;
  }
}

.tab {
  border: var(--button-border);
  border-radius: 0.5em;
  color: var(--button-color-secondary);
  font-size: 1.125em;
  line-height: 1.5em;
  padding: 0.5em 1.25em;
  cursor: pointer;

  &_selected {
    background: var(--tab-selected-bg);
  }

  @include mobile {
    font-size: 0.875em;
  }
}

.body {
  max-width: 60em;
  width: 100%;
  margin: auto;
  padding: 4em 0;

  @include mobile {
    padding: 2em 1em;
  }
}

.sectionLabel {
  font-size: 1.75em;
  font-family: 'FredokaOne';
  line-height: 1.5em;
  padding-bottom: 0.25em;
  border-bottom: var(--border-tertiary);
  border-width: 0.03125em;
}

.sectionContent {
  padding: 4em 0;
  display: flex;
  justify-content: space-between;

  @include mobile {
    flex-direction: column;
    gap: 1em;
    padding: 2em 0;
  }
}

.sectionCol {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 2em;
  max-width: 28.375em;

  @include mobile {
    gap: 1em;
  }
}

.faqItem {
  display: flex;

  @include mobile {
    background: var(--page-header-bg);
    border-radius: 0.25em;
    padding: 0.75em 0.75em 0.75em 0.5em;
  }
}

.bananaToken {
  height: 1.25em;
  margin-right: 0.5em;
}

.faqContent {
  height: auto;
}

.question {
  font-size: 0.875em;
  font-weight: 600;
  line-height: 1.5em;
  margin-bottom: 0.75em;

  @include mobile {
    font-size: 0.75em;
    margin-bottom: 0.5em;
  }
}

.answer {
  font-size: 0.875em;
  line-height: 1.375em;
  color: var(--label-color);

  @include mobile {
    font-size: 0.75em;
    line-height: 1.5em;
  }
}


.contact {
  width: fit-content;
  margin: auto;
  padding-bottom: 9em;

  @include mobile {
    padding: 0 1em 4em;
  }
}

.contactButton {
  position: relative;
  width: fit-content;
  font-size: 1.125em;
  border-radius: 0.5em;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  padding: 0.75em 2em;
  color: #FFF;
  background-color: var(--button-bg-tertiary);
  margin: auto;

  svg {
    margin-left: 0.75em;
  }

  @include mobile {
    font-size: 1em;
  }
}

.contactText {
  font-size: 0.875em;
  line-height: 1.3em;
  text-align: center;
  color: var(--label-color);
  max-width: 29em;
  padding-top: 2em;
}
