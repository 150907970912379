@import '../../styles';

.container {
  position: relative;
  font-size: .875em;
  width: 100%;
}

.header {
  display: none;
  border: var(--border-tertiary);
  border-width: 0.1em;
  background-color: var(--dropdown-bg);

  @include mobile {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: .75em 1em;
    border-radius: .25em;

    &_bd {
      border-radius: .25em .25em 0 0;
    }
  }

  @include tablet {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: .75em 1em;
    border-radius: .25em;
    white-space: nowrap;

    &_bd {
      border-radius: .25em .25em 0 0;
    }
  }
}

.dropdown {
  width: .875em;
  fill: var(--svg-fill-tertiary);
  margin-left: 1em;
}

.tabs {
  display: flex;
  border: var(--border-tertiary);
  border-width: 0.1em;
  background-color: var(--dropdown-bg);
  border-radius: .25em;
  overflow: hidden;

  @include mobile {
    display: none;
    position: absolute;
    top: 98%;
    left: 0;
    right: 0;
    flex-direction: column;
    border-radius:0 0 .25em .25em;
    z-index: $z-index-dropdown;

    &_show {
      display: flex;
    }
  }

  @include tablet {
    display: none;
    position: absolute;
    top: 98%;
    left: 0;
    right: 0;
    flex-direction: column;
    border-radius: 0 0 .25em .25em;
    z-index: $z-index-dropdown;

    &_show {
      display: flex;
    }
  }
}

.tab {
  padding: .75em 1.5em;
  cursor: pointer;
  border-left: var(--border-tertiary);
  border-width: 0.1em;

  &:first-child {
    border: none;
    border-radius: .25em 0 0 .25em;
  }

  &:last-child {
    border-radius: 0 .25em .25em 0;
  }

  &_selected {
    background-color: var(--tab-selected-bg);
    font-family: 'Lato Bold';
  }

  @include mobile {
    border: none;

    &_hidden {
      display: none;
    }

    &:hover {
      background-color: var(--dropdown-li-bg-hover);
    }
  }

  @include tablet {
    border: none;

    &_hidden {
      display: none;
    }

    &:hover {
      background-color: var(--dropdown-li-bg-hover);
    }
  }
}

.outside_width {
  @include mobile {
    width: 100%;
  }

  @include tablet {
    min-width: 10em;
  }
}
