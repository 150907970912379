@import '../../styles';

.container {
  max-width: 30em;
  position: relative;
  background-color: var(--modal-bg);
  display: flex;
  flex-direction: column;
  border-radius: 0.75em;
  overflow: hidden;
  margin: auto;
  max-height: inherit;
  font-family: 'Lato Regular';
}

.cancel {
  position: absolute;
  right: 1.8em;
  top: 1.8em;
  cursor: pointer;
  fill: var(--svg-fill-tertiary);

  @include mobile {
    right: 1em;
    top: 1em;
  }
}

.header {
  width: 100%;
  background-color: var(--modal-header-bg);
  padding: 1.5em;

  @include mobile {
    padding: 1em;
  }
}

.title {
  display: block;
  font-size: 1.375em;
  font-family: 'Lato Bold';
  text-align: center;
  padding-bottom: 1.25em;

  @include mobile {
    font-size: 1em;
  }
}

.scene {
  width: 100%;
  display: flex;
  gap: 1.25em;

  @include mobile {
    gap: 1em;
  }
}

.img {
  max-width: 9.5em;
  border-radius: 0.5em;
  border: 0.0625em solid var(--border-color-primary);

  @include mobile {
    max-width: 7.625em;
    border-radius: 0.25em;
  }
}

.scene_details {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.scene_title {
  font-size: 0.875em;
  font-weight: 600;
  line-height: 1.5em;

  @include mobile {
    font-size: 0.75em;
  }
}

.scene_id {
  color: var(--title-color);
  font-size: 0.625em;
  font-weight: 600;
  line-height: 2em;
}

.scene_contributed {
  font-size: 0.875em;
  line-height: 1.5em;
  color: var(--sub-title-color);

  @include mobile {
    font-size: 0.75em;
  }
}

.content {
  display: flex;
  flex-direction: column;
  gap: 1em;
  padding: 1.5em;
  background-color: var(--modal-body-bg);

  @include mobile {
    padding: 1em;
  }
}

.input_wrap {
  width: 100%;
}

.input_tip {
  display: flex;
  justify-content: space-between;
  line-height: 1.5em;
  margin-bottom: 0.5em;
  font-size: 0.875em;

  @include mobile {
    font-size: 0.75em;
  }
}

.input_avail {
  display: flex;
  align-items: center;
  gap: 0.5em;
}

.input_avail_label {
  color: var(--sub-title-color);
}

.input {
  width: 100%;
  border-radius: 0.25em;
  font-size: 1em;
  font-family: 'Lato Regular';
  border: var(--border-tertiary);
  padding: 0.875em;
  height: 3.5em;
  background-color: #FFF;

  &:focus {
    border-color: var(--border-color-secondary);
  }

  &:disabled {
    border: none;
  }
}

.info {
  display: flex;
  padding: .875em;
  margin-top: 1.25em;
  border: var(--border-tertiary);
  background: rgba(195, 198, 207, 0.2);
  overflow-y: auto;

  &_mg2 {
    margin: 1em 0 2em;
  }
}

.info_icon {
  fill: var(--app-color);
  margin-top: .25em;
}

.info_text {
  font-family: 'Barlow Regular';
  font-size: .9em;
  line-height: 1.5em;
  padding-left: .75em;
  flex: 20;
  display: flex;
  flex-direction: column;

  &_row {
    display: flex;
    align-items: baseline;
  }

  p {
    margin: 0;
    margin-top: 1em;

    &:first-of-type {
      margin-top: 0;
    }
  }
}

