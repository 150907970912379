.container {
  width: 100%;
  background: var(--trait-box-bg);
  border-radius: .25em;
  padding: .875em .75em;
}

.label {
  font-size: 0.9em;
  font-family: 'Barlow Regular';
  line-height: 2em;
  text-transform: uppercase;
}

.trait {
  font-family: 'Lato Bold';
  text-transform: capitalize;
}

.percentage {
  font-size: .875em;
  font-family: 'Lato Regular';
  line-height: 2em;
}