@import "../../styles";

.component {
  position: relative;
  width: 100%;
}

.bar {
  width: 100%;
  -webkit-appearance: none;
  appearance: none;
  border-radius: 0.5em;
  height: 0.625em;
  outline: none;
  background: var(--strip-bar-bg);
  overflow: hidden;

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none; 
    // height: 0.625em;
    // width: 0.625em;
    // background-color: #B04700;
    // border-radius: 0.625em;
  }

  &::-moz-range-thumb {
    -webkit-appearance: none;
    appearance: none;
    border: none;
    background-color: transparent;
    // height: 0.625em;
    // width: 0.625em;
    // background-color: #B04700;
    // border-radius: 0.625em;
  }
}

.info {
  display: flex;
  justify-content: space-between;
  font-size: 0.625em;
  font-weight: 600;
  line-height: 2em;
}
