/* Barlow Fonts */
@font-face {
  font-family: 'Barlow ExtraLight';
  src: url('./Barlow/Barlow-ExtraLight.ttf');
}

@font-face {
  font-family: 'Barlow ExtraLightItalic';
  src: url('./Barlow/Barlow-ExtraLightItalic.ttf');
}

@font-face {
  font-family: 'Barlow Light';
  src: url('./Barlow/Barlow-Light.ttf');
}

@font-face {
  font-family: 'Barlow LightItalic';
  src: url('./Barlow/Barlow-LightItalic.ttf');
}

@font-face {
  font-family: 'Barlow Regular';
  src: url('./Barlow/Barlow-Regular.ttf');
}

@font-face {
  font-family: 'Barlow SemiBold';
  src: url('./Barlow/Barlow-SemiBold.ttf');
}

@font-face {
  font-family: 'Barlow SemiBoldItalic';
  src: url('./Barlow/Barlow-SemiBoldItalic.ttf');
}

@font-face {
  font-family: 'Barlow Bold';
  src: url('./Barlow/Barlow-Bold.ttf');
}

@font-face {
  font-family: 'Barlow BoldItalic';
  src: url('./Barlow/Barlow-BoldItalic.ttf');
}


/* Lato Fonts */
@font-face {
  font-family: 'Lato Regular';
  src: url('./Lato/Lato-Regular.ttf');
}

@font-face {
  font-family: 'Lato Bold';
  src: url('./Lato/Lato-Bold.ttf');
}

@font-face {
  font-family: 'Lato BoldItalic';
  src: url('./Lato/Lato-BoldItalic.ttf');
}

@font-face {
  font-family: 'Lato Black';
  src: url('./Lato/Lato-Black.ttf');
}


/* FredokaOne Fonts */
@font-face {
  font-family: 'FredokaOne';
  src: url('./Fredoka_One/FredokaOne-Regular.ttf');
}

