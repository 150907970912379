:root,
:root.light {
  --recall-bg:##FFCFA6;
  --app-bg: #FFCFA6;
  --app-color: #101629;
  --form-bg: #FFFBF6;
  --footer-bg: #D7BEA3;
  --data-box-bg: #FFF;
  --trait-box-bg: #EEEEEE;
  --card-bg: #FFF;
  --card-bg2: #FFF3E7;
  --card-bg3: #FFF;
  --section-bg: #FFEFDD;
  --section-bg2: #FFE5CF;
  --section-bg3: #FFD5B0;
  --modal-bg: #FFEFDD;
  --modal-header-bg: #FFEFDD;
  --modal-body-bg: #FFF;
  --nav-bg: #F94F01;
  --strip-bar-bg: #FFCFA6;
  --page-header-bg: #FFE0C6;
  --dropdown-bg: #FFF;
  --dropdown-li-bg-hover: #FFCFA6;
  --dropdown-selected-bg: #F94F01;
  --button-dropdown-bg-hover: rgba(249, 79, 1, 0.3);
  --tab-selected-bg: #FFCFA6;
  --button-bg-primary: #F94F01;
  --button-color-primary: #FFF;
  --button-bg-primary-hover: #FF8045;
  --button-bg-secondary: transparent;
  --button-color-secondary: #F94F01;
  --button-bg-secondary-hover: rgba(255, 128, 69, 0.25);
  --button-bg-tertiary: #283567;
  --button-color-tertiary: #F94F01;
  --button-bg-disabled: #C3C6CF;
  --button-bg-loading: #B04700;
  --button-color-disabled: #555A69;
  --button-border: 0.125em solid #F94F01;
  --small-button-bg: #FFCFA6;
  --input-bg-primary: #FFF;
  --input-color-primary: #555A69;
  --input-bg-secondary: #FFF;
  --border-color-primary: #101629;
  --border-color-secondary: #101629;
  --border-color-tertiary: #C3C6CE;
  --border-color-quaternary: #B78E61;
  --border-primary: 0.0625em solid #FFCFA6;
  --border-secondary: 0.0625em solid #555A69;
  --border-tertiary: 0.0625em solid var(--border-color-tertiary);
  --box-shadow-color: rgba(195, 198, 207, 0.5);
  --svg-fill-primary: #F94F01;
  --svg-fill-secondary: #F94F01;
  --svg-fill-tertiary: #101629;
  --label-color: #555A69;
  --label-color2: #F94F01;
  --label-tab-bg: #FFEFDD;
  --title-color: #F94F01;
  --sub-title-color: #767676;
  --table-cell-color: #555A69;
  --table-collpased-bg: #FFFBF6;
  --table-bg: #FFE0C6;
  --table-bg2: #FFCFA6;
  --message-bg: #F8EFE5;
  --wallet-bg: #FFEFDD;
  --highlight-color: #B04700;
  --banner-bg: #2F3B63;
  --banner-color: #FFF;
}

:root.dark {
  --recall-bg: #141C33;
  --recall-card-bg: #FFF;
  --app-bg: #0B1020;
  --app-color: #FFF;
  --form-bg: #0B1020;
  --footer-bg: #2F3B63;
  --data-box-bg: #070B18;
  --trait-box-bg: #070B18;
  --card-bg: #141C33;
  --card-bg2: #141C33;
  --card-bg3: #2F3B63;
  --section-bg: #202946;
  --section-bg2: #0F162C;
  --section-bg3: #202946;
  --modal-bg: #141C33;
  --modal-header-bg: #2F3B63;
  --modal-body-bg: #141C33;
  --nav-bg: #000;
  --strip-bar-bg: #2F3B63;
  --page-header-bg: #0F162C;
  --dropdown-bg: #141C33;
  --dropdown-li-bg-hover: #2F3B63;
  --dropdown-selected-bg: #385AE1;
  --button-dropdown-bg-hover: rgba(56, 90, 225, 0.3);
  --tab-selected-bg: #385AE1;
  --button-bg-primary: #385AE1;
  --button-color-primary: #FFF;
  --button-bg-primary-hover: #4C6CED;
  --button-bg-secondary: transparent;
  --button-color-secondary: #FFF;
  --button-bg-secondary-hover: #2F3B63;
  --button-bg-tertiary: #385AE1;
  --button-color-tertiary: #FFF;
  --button-bg-disabled: #C3C6CF;
  --button-bg-loading: #385AE1;
  --button-color-disabled: #555A69;
  --button-border: 0.125em solid #385AE1;
  --small-button-bg: #2F3B63;
  --input-bg-primary: #C3C6CF;
  --input-color-primary: #070B18;
  --input-bg-secondary: #0B1020;
  --border-color-primary: #141C33;
  --border-color-secondary: #2F3B63;
  --border-color-tertiary: #2F3B63;
  --border-color-quaternary: #2F3B63;
  --border-primary: 0.0625em solid #2F3B63;
  --border-secondary: 0.0625em solid #2F3B63;
  --border-tertiary: 0.0625em solid var(--border-color-tertiary);
  --box-shadow-color: #1B2443;
  --svg-fill-primary: #385AE1;
  --svg-fill-secondary: #FFF;
  --svg-fill-tertiary: #FFF;
  --label-color: #C3C6CF;
  --label-color2: #C3C6CF;
  --label-tab-bg: #2F3B63;
  --title-color: #385AE1;
  --sub-title-color: #C3C6CF;
  --table-cell-color: #FFF;
  --table-collpased-bg: #2F3B63;
  --table-bg: #141C33;
  --table-bg2: #2F3B63;
  --message-bg: #2F3B63;
  --wallet-bg: #385AE1;
  --highlight-color: #385AE1;
  --banner-bg: #FFCFA6;
  --banner-color: #101629;
}