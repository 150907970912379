@import '../../styles';

.container {
  display: grid;
  grid-template-columns: 1fr 1.5fr;
  gap: 1.5em;
  margin-top: 2em;
  position: relative;

  @include mobile {
    display: flex;
    flex-direction: column;
    gap: 0;
    background-color: var(--card-bg);
    border-radius: .5em;
    padding: 1.5em 1.25em;
    margin-top: 40%;
  }
}

.contrainer_no_margin {
  display: none;
  @include mobile {
    margin-top: 1.5em;
    display: block;
  }
}

.img_wrapper {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 100%;
  
  @include mobile {
    margin-right: 0;
    padding: 0 20vw;
    width: auto;
    height: auto;
    margin-top: -40%;
  }
}

.img {
  width: 100%;
  border-radius: .25em;
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.title {
  font-family: 'FredokaOne';
  font-size: 2.0625em;
  padding: .25em 0;

  @include mobile {
    font-size: 2em;
    padding: .675em 0 0 0;
    text-align: center;
  }

  @include tablet {
    font-size: 2em;
  }
}

.description {
  display: block;
  font-family: 'Barlow Regular';
  margin-bottom: .75em;

  @include mobile {
    text-align: center;
    margin-bottom: 0;
    margin-top: 0.675em;
    order: 1;
  }
}

.control_wrapper {
  background-color: var(--card-bg);
  border-radius: .25em;
  padding: 24px;
  margin-top: auto;
}

.price {
  display: flex;
  flex-direction: column;
  line-height: 1.875em;

  @include mobile {
    line-height: 1.5em;
  }
}

.label {
  font-size: 1.125em;
  font-family: 'Barlow Regular';
}

.value {
  font-size: 1.4em;
  font-family: 'Barlow SemiBold';
  word-break: break-word;

  @include mobile {
    margin-top: 0.25em;
  }
}

.not_sale {
  font-family: 'Barlow Regular';
}

.group_button { 
  display: flex;
  flex-direction: row;

  @include mobile {
    flex-direction: column;
    margin-top: 1em;
  }
}

.buttons {
  display: flex;
  flex-direction: column;
}

.btn {
  height: 2.5em;
  margin-top: 1.5em;

  @include mobile {
    margin-top: 0.5em
  }
}

.button {
  width: 100%;
  height: 2.5em;
  border: none;
  border-radius: .5em;
  font-size: 1.125em;
  font-family: 'Lato Bold';
  color: #FFF;
  background-color: #F94F01;
  cursor: pointer;
  margin: 1.5em 0 0 0;

  @include mobile {
    margin-top: 0.5em
  }
}

.button2 {
  width: 100%;
  height: 2.5em;
  border: .125em solid #F94F01;
  border-radius: .5em;
  font-size: 1.125em;
  font-family: 'Lato Bold';
  color: #F94F01;
  background-color: transparent;
  cursor: pointer;
  margin: 1.5em 0 0 0;

  @include mobile {
    margin: 0;
    margin-top: 0.5em
  }

  &:disabled {
    visibility: hidden;
  }
}

.edit_button_wrap {
  display: flex;
  width: 100%;
  position: relative;
}

.edit_button {
  width: 100%;
  height: 2.5em;
  border: var(--button-border);
  border-right: 0;
  border-radius: .5em 0 0 .5em;
  font-size: 1.125em;
  font-family: 'Lato Bold';
  color: var(--button-color-secondary);
  background-color: var(--button-bg-secondary);
  cursor: pointer;
  white-space: nowrap;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  display: inline-block;
  margin: 1.5em 0 0 0;

  @include mobile {
    margin-top: 0.5em;
  }
}

.edit_dropdown_button {
  border-right: var(--button-border);
  border-radius: 0 .5em .5em 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 3em;

  svg {
    fill: var(--svg-fill-primary);
  }
}

.edit_dropdown_button_trigger {
  &:focus {
    // background: rgba(249, 79, 1, 0.3);
    background: var(--button-dropdown-bg-hover);
    &+ul {
      display: block;
    }
  }
}

.edit_dropdown_content {
  background-color: var(--dropdown-bg);
  position: absolute;
  top: 3.75em;
  z-index: 1;
  min-width: 10em;
  right: 0;
  list-style-type: none;
  padding: 0;
  display: none;
}

.edit_dropdown_item {
  font-size: 0.875em;
  line-height: 1.25em;
  font-family: "Lato Regular";
  padding: .5em 1em;
  border: var(--border-tertiary);
  cursor: pointer;
  border-radius: .25em;

  &:hover {
    background-color: var(--button-dropdown-bg-hover);
  }
}

.divider {
  width: 2.5em;
}

.sub_price_wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;

  @include mobile {
    flex-direction: column;
    align-items: flex-start;
  }
}

.sub_price_label {
  display: flex;
  align-items: center;
  font-size: 0.875em;
  font-family: 'Lato Regular';
  svg {
    margin-right: 0.375em;
  }

  @include mobile {
    margin-top: 1em;
  }
}

.sub_price_bold {
  font-weight: bold;
  margin-left: 0.25em;
}

.overflow_button {
  width: 2.5em;
  height: 2.5em;
  background-color: var(--dropdown-bg);
  border-radius: .25em;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 0;
  top: 0;
  cursor: pointer;

  svg {
    path {
      fill: var(--svg-fill-primary);
    }
  }
  
  @include mobile {
    right: -1.5em;
  }

  &:hover {
    ul {
      display: block;
      transition-delay: 0.5s;
    }
  }
}

.dropdown_content {
  background-color: transparent;
  position: absolute;
  top: 1.25em;
  z-index: 1;
  min-width: 10em;
  right: -7.5em;
  list-style-type: none;
  padding: .5em 0 0 0;
  display: none;
}

.dropdown_item {
  font-size: 0.875em;
  line-height: 1.25em;
  font-family: "Lato Regular";
  padding: .5em 1em;
  border: var(--border-tertiary);
  border-bottom: none;
  cursor: pointer;
  background-color: var(--dropdown-bg);

  &:first-child {
    border-radius: .25em .25em 0 0;
  }

  &:last-child {
    border-radius: 0 0 .25em .25em;
    border-bottom: var(--border-tertiary);
  }

  &:hover {
    background-color: var(--button-dropdown-bg-hover);
  }
}

.hide_on_mobile {
  @include mobile {
    display: none;
  }
}

.hide_on_desktop {
  display: none;
  @include mobile {
    display: flex;
  }
}

.bottom_sheet_wrap {
  transition: opacity 500ms;
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 998;
  width: 100%;
  height: 100%;
}

.bottom_sheet_block_layer {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: rgba(16, 22, 41, 0.4);
}

.bottom_sheet {
  position: absolute;
  bottom: 0;
  z-index: 999;
  width: 100%;
  width: 100%;
  background-color: var(--table-collpased-bg);
  padding: 1em;
  padding-left: inherit;
  padding-right: inherit;
  font-family: 'Lato Regular';
}

.bottom_sheet_header {
  text-align: center;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 1em;
  margin-bottom: 1.5em;
  font-size: 1.375em;

  svg {
    position: absolute;
    left: 1em;
    fill: var(--svg-fill-tertiary);
  }
}

.bottom_sheet_list_menu {
  padding: 0 1em;

  svg {
    path {
      fill: var(--svg-fill-tertiary);
    }
  }
}

.bottm_sheet_menu_item {
  font-size: 1.125em;
  margin: 1em 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  span {
    margin-left: 0.5em;
  }

  &:last-child {
    margin-bottom: 0;
  }
}