@import '../../styles';

.container {
  font-family: Lato Regular;

  @include mobile {
    padding: 0 1em 2em;
  }
}

.header {
  background: var(--page-header-bg);
  position: relative;
  overflow: hidden;
}

.goBack {
  display: flex;
  align-items: center;
  font-family: 'Barlow SemiBold';
  font-size: 1.125em;
  line-height: 2em;
  text-decoration-line: underline;
  color: inherit;
  width: fit-content;

  svg {
    margin-right: 0.75em;
    fill: var(--svg-fill-tertiary);
  }

  @include mobile {
    display: none;
  }
}

.headerInner {
  max-width: 60em;
  margin: auto;
  padding: 2.5em 0;

  @include mobile {
    padding: 1.5em 2em;
  }
}

.dataContent {
  display: flex;
  align-items: stretch;
  gap: 1.5em;
  padding-top: 1.5em;

  @include mobile {
    flex-direction: column;
    padding: 0;
  }
}

.dataItem {
  background: var(--card-bg2);
  border: 0.125em solid var(--border-color-secondary);
  box-shadow: 0em 0.25em 0em #101629;
  border-radius: 0.75em;
  padding: 1em 1.5em;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 11.25em;

  @include mobile {
    height: 8.5em;
  }
}

.dataLabel {
  display: flex;
  align-items: center;
  font-weight: 700;
  font-size: 1.125em;
  line-height: 1.5em;
  color: var(--label-color);

  @include mobile {
    font-size: 1em;
  }
}

.dataValue {
  display: flex;
  align-items: baseline;
}

.dataValue_main {
  font-family: 'FredokaOne';
  font-size: 1.75em;
  line-height: 1.5em;

  @include mobile {
    font-size: 1.375em;
  }
}

.dataValue_sub {
  font-size: 0.875em;
  line-height: 1.5em;
  font-weight: 600;
  padding-left: 0.5em;
}

.bananasOwned {
  font-size: 1.75em;

  @include mobile {
    font-size: 1.375em;
  }
}

.dataDesc {
  line-height: 1.5em;
  height: 1.5em;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;

  span:first-of-type {
    font-size: 0.875em;
    font-weight: 700;  
    color: var(--label-color);
  }

  @include mobile {
    span:first-of-type {
      font-size: 0.75em;
    }
  }
}

.redirect {
  font-size: 1em;
  width: 7em;
}

.stakeAlert {
  display: flex;
  align-items: center;
  padding: 0.75em 2em;
  gap: 1em;
  border-radius: 0.5em;
  background: var(--strip-bar-bg);
  margin-top: 1.5em;

  @include mobile {
    padding: 0.75em 1em;
  }
}

.alertSpeaker {
  max-width: 1em;
}

.stakeAlertText {
  font-size: 0.875em;
  line-height: 1.5em;
}

.body {
  max-width: 60em;
  width: 100%;
  margin: auto;
  padding: 0 0 5em;
}

.switches {
  display: flex;
  align-items: stretch;
  justify-content: center;
  width: 100%;
  gap: 2em;
  padding: 1.5em 0;
}

.switchTab {
  font-size: 1em;
  font-weight: 600;
  line-height: 1.75em;
  color: var(--label-color);
  padding: 0 1em;
  border-bottom-width: 0.1875em;
  cursor: pointer;

  &_active {
    border-bottom: var(--button-border);
  }
}

.loading {
  width: 100%;
  font-size: 1.5em;
  text-align: center;
}
