@import '../../styles';

/* General */
.container {
  max-width: 30em;
  position: relative;
  background-color: var(--modal-bg);
  display: flex;
  flex-direction: column;
  border-radius: 0.75em;
  overflow: hidden;
  margin: auto;
  max-height: inherit;
  font-family: 'Lato Regular';
}

.container_body {
  max-width: 28em;
  padding: 1.5em;

  @include mobile {
    padding: 1em;
  }
}

.cancel {
  position: absolute;
  right: 1.8em;
  top: 1.8em;
  cursor: pointer;
  fill: var(--svg-fill-tertiary);

  @include mobile {
    right: 1em;
    top: 1em;
  }
}

.back {
  position: absolute;
  left: 1.8em;
  top: 1.8em;
  width: .875em;
  height: .875em;
  cursor: pointer;
  fill: var(--svg-fill-tertiary);

  @include mobile {
    left: 1em;
    top: 1em;
  }
}

.header {
  background-color: var(--modal-header-bg);
  padding: 1.5em;

  @include mobile {
    padding: 1em;
  }
}

.title {
  display: block;
  font-size: 1.375em;
  font-family: 'Lato Bold';
  text-align: center;
  padding-bottom: 1.25em;
}

.title_alone {
  line-height: 1.5em;
  padding-bottom: 0em;
}

.summary {
  display: flex;
}

.img {
  width: 5em;
  height: 5em;
  border-radius: .25em;
  margin-right: 1.5em;
}

.chimp_id {
  font-size: 1.125em;
  font-family: 'Barlow SemiBold';
  line-height: 2em;
}

.token_id {
  font-size: 0.875em;
  line-height: 1.5em;
}

.content {
  display: flex;
  flex-direction: column;
  padding: 1.5em;
  background-color: var(--modal-body-bg);

  @include mobile {
    padding: 1em;
  }
}

.content_row {
  display: flex;
  justify-content: space-between;
  line-height: 2.25em;
  word-break: break-all;
}

.content_label {
  min-width: 6em;
  padding-right: .5em;
}

.label {
  margin-bottom: 0.5em;
}

.input {
  border-radius: 0.25em;
  border: var(--border-secondary);
  font-size: 1em;
  font-family: 'Lato Regular';
  padding: 0.875em;
  height: 3.5em;
  background-color: #FFF;

  &:disabled {
    border: none;
  }
}

.input_remark {
  font-size: 0.875em;
  margin-top: .5em;
  color: var(--sub-title-color);
}

.buttons {
  padding-top: 1.5em;
}

.info {
  display: flex;
  padding: .875em;
  margin-top: 1.25em;
  border: var(--border-tertiary);
  background: rgba(195, 198, 207, 0.2);
  overflow-y: auto;

  &_mg2 {
    margin: 1em 0 2em;
  }
}

.info_icon {
  fill: var(--app-color);
  margin-top: .25em;
}

.info_dot {
  width: 0.375em;
  min-width: 0.375em;
  margin: 0 0.25em;

  circle {
    fill: var(--app-color);
  }
}

.info_text {
  font-family: 'Barlow Regular';
  font-size: .9em;
  line-height: 1.5em;
  padding-left: .75em;
  flex: 20;
  display: flex;
  flex-direction: column;

  &_row {
    display: flex;
    align-items: baseline;
  }

  p {
    margin: 0;
    margin-top: 1em;

    &:first-of-type {
      margin-top: 0;
    }
  }
}




/* For Sell Chimp */
.pre_header {
  display: flex;
}

.pre_img {
  width: 6em;
  height: 6em;
  border-radius: .25em;
  margin-right: 1.5em;
}

.pre_summary {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.pre_chimp_id {
  font-size: 1.1875em;
}

.pre_download {
  font-size: .9em;
  font-family: 'Barlow Regular';
  color: var(--button-color-secondary);
  margin-top: 1em;
}

.pre_button {
  margin-top: 0.75em;
  border-radius: 0.25em !important;
  border-width: 0.1em !important;
}


/* For Remove Chimp */
.remove_content {
  font-size: 1.125em;
  font-family: 'Barlow Regular';
  line-height: 1.375em;
  color: var(--sub-title-color);
  text-align: center;
  margin-bottom: 2em;

  @include mobile {
    font-size: 1em;
  }
}



/* Warning parts */
.warning {
  background-color: #F6516633;
  border: .0625em solid #F65166;
  display: flex;
  padding: 1em;
  margin-top: 1em;
}

.warning_icon {
  width: 1.8em;
  height: 0.9375em;
  margin-right: .5em;
  fill: var(--app-color);
}

.warning_text {
  font-size: .9em;
  font-family: 'Barlow Regular';
  line-height: 1.125em;
}



/* Success parts */
.success {
  padding: 1.5em;
  align-items: center;
  overflow: initial;

  @include mobile {
    padding: 1em;
  }
}

.success_icon {
  width: 4em;
  height: 4em;
  margin: 3em 0 1.5em 0;
}

.success_title {
  font-size: 2em;
  font-family: 'FredokaOne';
  line-height: 2em;

  @include mobile {
    font-size: 1.75em;
  }
}

.success_subtitle {
  font-family: 'Lato Bold';
  font-size: 1.375em;
  line-height: 2em;

  @include mobile {
    font-size: 1.125em;
  }
}

.success_desc,
.success_explore {
  font-size: 1.125em;
  color: var(--sub-title-color);
  line-height: 1.5em;
  text-align: center;
  margin-top: 0.5em;
}

.success_explore a {
  font-family: 'Lato Bold';
  text-decoration: underline;
  color: var(--sub-title-color);
}

.success_button {
  margin-top: 0.5em;

  &:first-of-type {
    margin-top: 1.5em;
  }
}

/* Offer chimp */ 
.offer_info {
  margin: 1.5em 0 0 0;
}

.offer_info_text {
  text-align: left;
}

.offer_input {
  &:disabled {
    border: .0125em solid #C3C6CE;
  }
}

.offer_label {
  color: var(--label-color);
  min-width: 6em;
  padding-right: .5em;
  font-size: 1.125em;
}

.offer_value {
  color: var(--label-color);
  text-align: right;
  font-size: 1.125em;
}

.offer_divider {
  border-top: var(--border-tertiary);
  margin: 1em 0;
}

.offer_total_earnings {
  font-weight: bold;
  font-size: 1.125em;
  line-height: 1.75em;
}

.offer_total_value {
  font-weight: bold;
  font-size: 1.125em;
  line-height: 1.75em;
  text-align: right;
}

.alert {
  display: flex;
  padding: .675em;
  border: .0625em solid #FFE600;;
  color: #101629;
  background: #FFFACC;
  margin-top: 1.5em;

  &_mg2 {
    margin: 1em 0 2em;
  }
}

.chimp_logo_wrap {
  display: flex;
  align-items: center;
  justify-content: center;
}

.chimp_logo {
  background-color: #000000;
  width: 4em;
  height: 4em;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 1.25em;

  img {
    width: 3.5em;
    height: 3.5em;
  }
}

.error_icon {
  width: 4em;
  height: 4em;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 1.25em;

  svg {
    width: inherit;
    height: inherit;
  }
}

.message {
  font-size: 1.125em;
  line-height: 1.5em;
  text-align: center;
  color: var(--sub-title-color);
  padding: 1em 0;
}
