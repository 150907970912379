@import '../../styles';

.mb {
  display: none;

  @include mobile {
    width: 100%;
    display: flex;
    font-family: "Lato Regular";
    text-transform: capitalize;
    line-height: 3em;
  }
}

.dk {
  display: flex;
  border-radius: 50%;
  cursor: pointer;
  margin-right: 1.5em;

  @include mobile {
    display: none;
  }
}
