
.component {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.label {
  width: max-content;
  padding-right: 1em;
}

.button {
  width: 2.5em;
  height: 1.5em;
  background-color: transparent;
  border: var(--button-border);
  border-radius: 1.5em;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  &_checked {
    justify-content: flex-end;
    background-color: #2F3B63;
  }
}

.circle {
  width: 1.25em;
  height: 1.25em;
  background-color: #F94F01;
  border-radius: 1em;

  &_checked {
    justify-content: flex-end;
    background-color: #385AE1;
  }
}
