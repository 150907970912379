@import '../../styles';

.container {
  position: relative;
  max-width: 60em;
  margin: auto;
  padding: 2em 0 6em;
  display: flex;
  flex-direction: column;
  gap: 2em;

  @include mobile {
    padding: 1.5em 1em;
  }
}

.goBack {
  display: flex;
  align-items: center;
  font-family: 'Barlow SemiBold';
  font-size: 1.125em;
  line-height: 1.78em;
  text-decoration-line: underline;
  color: inherit;
  width: fit-content;
  padding: 1em 0;

  svg {
    margin-right: 0.75em;
    fill: var(--svg-fill-tertiary);
  }

  @include mobile {
    display: none;
  }
}

.page_title {
  font-size: 2.5em;
  font-family: "FredokaOne";
  letter-spacing: -0.0625em;

  @include mobile {
    display: none;
  }
}

.body {
  position: relative;
  display: flex;
  width: 100%;
  gap: 1.75em;

  @include mobile {
    flex-direction: column;
  }
}

.scroll_videos {
  position: relative;
  width: 66%;

  @include mobile {
    width: 100%;
  }
}

.scroll_main {
  aspect-ratio: 1.78 / 1;
}

.scroll_list_label {
  font-size: 0.625em;
  font-weight: 600;
  line-height: 2em;
  margin-top: 1.5em;

  @include mobile {
    display: none;
  }
}

.scroll_wrap {
  position: relative;
  width: 100%;
  margin-top: 0.5em;

  @include mobile {
    margin-top: 1em;
  }
}

.scroll_list {
  position: relative;
  overflow-x: auto;
  overflow-y: hidden;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  &_active {
    cursor: grabbing;
  }
}

.scroll_icon {
  position: absolute;
  top: calc(50% - 0.625em);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background: #FFEFDDDE;
  width: 1.75em;
  height: 1.75em;
  border-radius: 50%;

  svg {
    fill: var(--svg-fill-primary);
  }

  &_prev {
    left: 0.75em;

    svg {
      transform: rotate(90deg);
    }
  }

  &_next {
    right: 0.75em;

    svg {
      transform: rotate(-90deg);
    }
  }

  @include mobile {
    display: none;
  }
}

.scroll_list_scroll {
  display: flex;
  align-items: baseline;
  gap: 1em;
  width: fit-content;

  @include mobile {
    gap: 0.5em;
  }
}

.scroll_title {
  &_mb {
    display: none;
  }

  @include mobile {
    &_mb {
      display: initial;
    }

    &_dk{
      display: none;
    }
  }
}

.scene_details {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 1.75em;
  width: 34%;

  @include mobile {
    width: 100%;
  }
}

.details_item_label {
  color: var(--label-color2);
  font-size: 0.625em;
  font-weight: 600;
  line-height: 2em;
}

.details_title {
  font-size: 1.625em;
  font-weight: 600;

  @include mobile {
    font-size: 1.25em;
  }
}

.details_desc {
  font-size: 0.75em;
  line-height: 1.5em;
  text-align: justify;
}

.details_info {
  padding: 1.125em;
  background: var(--section-bg2);
  border-radius: 0.625em;
  display: flex;
  justify-content: space-between;
  text-align: center;
}

.details_info_label {
  color: var(--label-color2);
  font-size: 0.625em;
  font-weight: 600;
  line-height: 2em;
}

.details_info_value, .details_info_value2 {
  font-size: 0.875em;
  line-height: 2.35em;
}

.details_info_value2 {
  font-family: "FredokaOne";
}

.details_tip {
  display: flex;
  align-items: center;
  gap: 0.5em;
  margin-bottom: 1em;

  @include mobile {
    padding: 0 2em;
  }
}

.details_tip_msg {
  font-size: 0.625em;
  line-height: 1.4em;
  font-weight: 500;

  strong {
    font-style: italic;
  }
}

.details_tip_icon {
  width: 1.125em;
}


.scroll_warning {
  display: flex;
  align-items: center;
  padding: 0.75em 2em;
  gap: 1.5em;
  border-radius: 0.75em;
  background: var(--strip-bar-bg);

  @include mobile {
    display: none;
  }
}

.scroll_warning_icon {
  max-width: 1.875em;
}

.scroll_warning_msg {
  font-size: 0.875em;
  line-height: 1.5em;
}
