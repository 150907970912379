@import '../../styles';

.tab {
  position: relative;
  display: block;
  width: 100%;
  border: var(--border-tertiary);
  border-width: 0.0875em;
  border-radius: .25em;
  background-color: var(--dropdown-bg);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 0.875em;
  font-family: 'Lato Regular';
  height: 100%;

  &_opened {
    border-radius: .25em .25em 0 0;
  }
}

.header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.625em .75em;
}

.input {
  width: 100%;
  font-family: 'Lato Regular';
  border: none;
  font-size: 1.125em;
  background: transparent;
  color: var(--app-color);
}

.drop_icon {
  width: .875em;
  fill: var(--svg-fill-tertiary);
  margin-left: 1em;
}

.options {
  position: absolute;
  top: 100%;
  left: -0.0625em;
  right: -0.0625em;
  list-style: none;
  background-color: var(--dropdown-bg);
  z-index: $z-index-dropdown;
  margin: 0;
  padding: 0;
  border: var(--border-tertiary);
  border-width: 0.0875em;
  border-radius: 0 0 .25em .25em;
  font-size: 1.125em;
  font-family: 'Barlow Regular';
  max-height: 16em;
  overflow: auto;

  &::-webkit-scrollbar {
    width: 0.25em;
  }

  &::-webkit-scrollbar-track {
    background-color: #FFF;
  }
  
  &::-webkit-scrollbar-thumb {
    background-color: #767676;
    border-radius: 0.25em;
  }
}

.li {
  padding: .6em 1em;

  &:hover {
    background-color: var(--dropdown-li-bg-hover);
  }
}
