@import '../../styles';

.container {
  margin-top: 1.5em;
  background-color: var(--card-bg);
  padding: 1.5em;
  border-radius: .25em;

  @include mobile {
    border-radius: .5em;
    padding: 1.25em 1em;
  }
}

.container_fluid {
  padding: 1.5em 0;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 1.875em;

  @include mobile {
    padding-bottom: 1.25em;
  }
}

.header_not_fluid {
  padding-left: 1.5em;
  padding-right: 1.5em;
}

.label {
  font-size: 1.375em;
  font-family: 'Lato Bold';
}

.icon {
  cursor: pointer;
  fill: var(--svg-fill-secondary);
  
  &_closed {
    transform: rotate(180deg);
  }
}

.content {
  transition: max-height 500ms ease-in-out 0ms;

  &_opened {
    max-height: 800em;
  }
  
  &_closed {
    max-height: 0;
    overflow: hidden;
  }  
}
