@import '../../styles';

.container {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  bottom: 0;
}

.video {
  width: inherit;
  height: inherit;
  object-fit: cover;
  object-position: 50% bottom;
}

.cover {
  display: none;

  @include mobile {
    display: block;
    width: inherit;
    position: absolute;
    top: 4em;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: var(--nav-bg);
    opacity: 0.35;
  }
}

.content {
  background-color: var(--nav-bg);
  // width: 40em;
  padding: 1em 2em 1em calc(7em + 2.25em);
  position: absolute;
  right: 3em;
  bottom: 4em;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @include mobile {
    width: inherit;
    top: 4em;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: transparent;
    flex-direction: column;
    padding: 2em 1em;
    justify-content: center;
  }
}

.imgs {
  position: absolute;
  left: 1.125em;
  bottom: 0.75em;
  display: flex;
  flex-direction: column;
  max-width: 7em;

  img {
    width: 100%;
  }

  @include mobile {
    position: relative;
    left: initial;
    bottom: initial;
    max-width: 10em;
  }
}

.text {
  font-size: 1.125em;
  font-family: 'Barlow Bold';
  text-transform: uppercase;
  color: #FFF;
  line-height: 1.75em;

  @include mobile {
    font-size: 1.25em;
    padding: 3.2em 0 2em;
  }
}

.redirect {
  width: fit-content;
  padding: 0 1em;
  color: #FFF;
  background-color: var(--button-bg-tertiary);
  border: none;

  &:hover {
    background-color: var(--button-bg-tertiary);
  }
}

.arrow {
  transform: rotate(180deg);
  fill: #FFF;
  margin-left: 1em;
}
