@import "../../styles";

.container {
  max-width: 56em;
  margin: auto;
  padding: 2em;
  font-family: "Lato Regular";

  @include mobile {
    padding: 0;
  }
}

.heading {
  font-family: "FredokaOne";
  
  @include mobile {
    display: none;
  }
}

.heading_mobile {
  font-family: "FredokaOne";
  margin: 0;
  margin-bottom: 1.5em;
  display: none;

  @include mobile {
    display: block;
  }
}

.wrapper {
  background-color: var(--card-bg);
  display: flex;
  border-radius: 0.5em;

  @include mobile {
    flex-direction: column;
  }
}

.info_wrapper {
  flex: 1;
  padding: 2.5em 1.5em;
  display: flex;
  align-items: flex-start;
  flex-direction: column;

  @include mobile {
    order: 2;
  }
}

.detail_wrapper {
  flex: 1;
  padding: 2.5em 1.5em;
  display: flex;
  align-items: flex-start;
  flex-direction: column;

  @include mobile {
    order: 1;
    padding: 0 1.5em;
    border-bottom: var(--border-primary);
    border-width: 0.125em;
  }
}

.shipping_info {
  width: 100%;
}

.form {
  margin-bottom: 2.5em;
}

.form_title {
  font-size: 1.375em;
  line-height: 0.5em;
  margin-bottom: 1em;
  font-weight: 600;
}

.field_group {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1em;
}

.field_group_phone {
  display: grid;
  grid-template-columns: 1fr 2fr;
  gap: 1em;
}

.field {
  margin-bottom: 1em;
}

.order_btn {
  width: 100%;
}

.detail_content {
  display: block;
  width: 100%;

  @include mobile {
    display: none;
  }
}

.detail_content_mb {
  display: none;
  width: 100%;
  
  @include mobile {
    display: block;
  }
}

.error {
  background-color: #FDDCE0;
  border: 0.0625em solid #F65166;
  padding: 0.875em 1em;
  color: #101629;
  font-size: 0.875em;
  margin-top: 0.5em;
  display: flex;
  align-items: flex-start;

  @include mobile {
    margin-top: 0;
  }
}

.error_icon {
  width: 1em;
  height: 1em;
  margin-right: .5em;
  fill: #101629;
}