@import "../../styles";

.component {
  position: relative;
  display: flex;
  height: 100%;
}

.video {
  width: 100%;
  box-shadow: 0.25em 0.25em 0em 0em var(--border-color-secondary);
  border-radius: 1em;
  object-fit: fill;
}

.play_icon {
  position: absolute;
  cursor: pointer;
  top: calc(50% - 30.5px);
  left: calc(50% - 30.5px);

  @include mobile {
    width: 3.25em;
    top: calc(50% - 26px);
    left: calc(50% - 26px);
  }
}

.locked {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(139, 139, 139, 0.5);
  backdrop-filter: blur(10px);
  border-radius: 1em;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.locked_icon {
  width: 1.3125em;
  height: 1.875em;

  path {
    fill: #FFF;
  }
}

.locked_txt {
  font-size: 1.125em;
  font-weight: 600;
  line-height: 2em;
  color: #FFF;
  text-align: center;
  padding: 0.5em 1em;

  @include mobile {
    font-size: 0.875em;
    line-height: 1.5em;
  }
}

.state {
  position: absolute;
  top: -0.5px;
  left: -0.5px;
  background: var(--app-bg);
  border-top-left-radius: 0.75em;
  border-bottom-right-radius: 0.75em;
  width: 5em;
  height: 2em;
  padding: 0.25em 0.375em;
  display: flex;
  align-items: center;
  gap: 0.25em;

  &::before{
    content:"";
    position:absolute;
    top: 100%;
    left: 0;
    width: 0.625em;
    height: 0.625em;
    background: radial-gradient(farthest-side at bottom right, transparent 98%, var(--app-bg) 100%) 
      0 0/0.625em 0.625em no-repeat;
  }

  &::after{
    content:"";
    position:absolute;
    left: 100%;
    top: 0;
    width: 0.625em;
    height: 0.625em;
    background: radial-gradient(farthest-side at bottom right, transparent 98%,var(--app-bg) 100%) 
      0 0/0.625em 0.625em no-repeat;
  }

  svg {
    path {
      fill: var(--app-color);
    };
  }

  span {
    font-size: 0.75em;
    font-weight: 600;
  }
}
