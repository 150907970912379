@import '../../styles';

.container {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(16, 22, 41, 0.8);
  backdrop-filter: blur(0.125em);
  z-index: $z-index-modal;
}

.body {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.content {
  position: absolute;
  min-width: 50vw;

  @include mobile {
    left: 1em;
    right: 1em;
  }

  @include tablet {
    min-width: 70vw;
  }
}

.wrapper {
  max-height: calc(100vh - 2em);
}
