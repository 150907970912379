@import '../../styles';

$nav-color: #FFF;

.container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: "Barlow Bold";
  background-color: var(--nav-bg);
  padding: 0 5vw;
  z-index: $z-index-nav;

  &_mb,
  &_tl {
    display: none;
  }

  @include mobile {
    padding: 0 1.5em;
    position: fixed;
    top: 0;
    width: 100%;
    height: 4em;

    &_mb {
      display: flex;
    }

    &_dk,
    &_tl {
      display: none;
    }
  }
}

.bars {
  display: flex;
  gap: 2em;

  @include mobile {
    position: fixed;
    left: 0;
    right: 0;
    top: 4em;
    bottom: 0;
    z-index: $z-index-layer2;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background-color: var(--app-bg);
    padding: 1.5em;
    overflow: auto;
    gap: 0em;
  }
}

.sub_bar_title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1.125em;
  font-family: "Lato Regular";
  font-weight: bold;
  text-transform: capitalize;

  svg {
    transform: rotate(90deg);
    margin-right: 1em;
    fill: var(--svg-fill-secondary);
  }
}

.sub_bar_divider {
  border-top: var(--border-primary);
  margin: 1.5em 0;
  width: 100%;
}

.nav {
  text-decoration: none;
  cursor: pointer;
  text-transform: uppercase;
  color: $nav-color;

  @include mobile {
    margin: 0;
    text-transform: capitalize;
    font-size: 1.125em;
    line-height: 3.5em;
    font-family: "Lato Regular";
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: var(--app-color);
  }
}

.nav_tree {
  display: flex;
  align-items: center;

  svg {
    width: 0.625em;
    margin-left: 0.25em;

    path {
      fill: #FFF;
    }
  }

  @include mobile {
    justify-content: space-between;
    width: 100%;

    svg {
      width: 0.875em;
      transform: rotate(-90deg);

      path {
        fill: var(--svg-fill-secondary);
      }
    }
  }
}

.nav_item_logo {
  display: flex;
  justify-content: center;
  margin-right: 1.5em;

  img {
    width: 4.75em;
    margin: 0.25em 0 0.1em;
  }

  @include mobile {
    margin: 0;
    img {
      width: 3.25em;
      height: 3.25em;
    }
  }
}

.nav_item_menu {
  flex: 1;
  display: flex;
}

.nav_item_menu_expand {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  @include desktop {
    &:hover {
      .menu_expand_wrap {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        transition-delay: 0.5s;
      }
    }
  }
}

.menu_expand_wrap {
  background-color: transparent;
  position: absolute;
  top: 1.125em;
  left: 0em;
  display: none;
  padding-top: 1.5em;
}

.menu_expand {
  min-width: 12em;
  padding: 0.75em 1.5em;
  background-color: var(--modal-bg);
  border-radius: 0.5em;
  box-shadow: 0 0 0.625em var(--box-shadow-color);
  border: var(--border-primary);

  ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }
}

.menu_expand_ellipsis {
  path {
    fill: $nav-color;
  }
}

.expand_menu_divider {
  border-top: var(--border-primary);
  margin: 0.75em 0;
  width: 100%;

  @include mobile {
    margin: 0;
  }
}

.expand_menu_item {
  font-size: 1em;
  font-family: "Lato Regular";
  text-transform: capitalize;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  color: var(--app-color);
  line-height: 3em;
}

.expand_menu_item_external {
  svg {
    position: relative;

    path {
      fill: var(--svg-fill-primary);
    }
  }

  @include mobile {
    svg path {
      fill: var(--svg-fill-secondary);
    }
  }
}

.expand_menu_social {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 1.5em 0;
  gap: 2em;
}

.memu {
  cursor: pointer;
  fill: $nav-color;
  width: 1.125em;
}

.home {
  text-decoration: none;
  font-size: 1.125em;
  letter-spacing: -0.02em;
  color: $nav-color;

  @include mobile {
    font-size: 1em;
    flex: 1;
  }
}

.wallet {
  position: relative;

  &:hover {
    .wallet_expand_wrap {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      transition-delay: 0.5s;
    }
  }
}

.wallet_expand_wrap {
  background-color: transparent;
  position: absolute;
  right: 0;
  display: none;
  min-width: 16em;
  padding-top: 0.5em;
}

.wallet_expand {
  min-width: 18em;
  padding: 1em 1.5em;
  background-color: var(--modal-bg);
  border-radius: 0.5em;
  box-shadow: 0 0 0.625em var(--box-shadow-color);
  border: var(--border-primary);

  ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }
}

.powerIcon {
  path {
    fill: var(--svg-fill-primary);
  }
}

.cart_order_icon {
  width: 2em;
  height: 2em;
  margin-right: 1em;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.cart_icon {
  path {
    fill: $nav-color;
  }
}

.cart_online_icon {
  position: absolute;
  top: 0.5em;
  right: 0.0675em;
  background-color: var(--nav-bg);
  width: 0.75em;
  height: 0.75em;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;

  svg {
    width: 0.5em;
    height: 0.5em;
  }
}
