@import '../../styles';

.container {

}

.header {
  display: flex;
  // padding: 3em 2em;

  @include mobile {
    // padding: 0.5em 0.25em;
  }

  @include tablet {
    // padding: 1.5em 1em;
  }
}

.club {
  width: 100%;
}

.divider {
  position: relative;
  width: 100%;
  height: .75em;
  background-color: #F94F01;

  @include mobile {
    height: 0.3125em;
  }

  @include tablet {
    height: 0.5em;
  }
}

.fluit_drop {
  position: absolute;
  top: .375em;
  right: 5%;

  @include mobile {
    width: 3.5em;
    top: .125em;
  }

  @include tablet {
    width: 5em;
    top: .25em;
  }
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 20%;

  @include mobile {
    padding: 0 1em;
  }

  @include tablet {
    padding: 0 1.5em;
  }
}

.section {
  width: 100%;
  padding-bottom: 7.5em;

  @include mobile {
    padding-bottom: 5em;
  }
}

.section:first-child {
  padding-top: 6em;

  @include mobile {
    padding-top: 3em;
  }
}

.title {
  display: block;
  font-family: 'FredokaOne';
  font-size: 2.5em;
  letter-spacing: -0.02em;
  text-transform: uppercase;

  @include mobile {
    font-size: 1.75em;
  }

  @include tablet {
    font-size: 2em;
  }
}

.desc {
  font-size: 1.125em;
  line-height: 1.625em;
  max-width: 37em;
  margin: 1.5em 0;

  &_highlighted {
    font-family: 'Lato Bold';
  }

  a {
    color: #F94F01;
    text-decoration: underline;
  }

  @include mobile {
    font-size: 1em;
  }
}

.theme_images {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: repeat(2, 1fr);
  gap: 2em;
  margin-top: 4em;

  @include mobile {
    grid-template-columns: repeat(3, 1fr);
    gap: 1em;
    margin-top: 3em;
  }

  @include tablet {
    grid-template-columns: repeat(4, 1fr);
    gap: 1.5em;
  }
}

.theme_img {
  width: 100%;
  border-radius: 0.9375em;
}

.hide_for_mobile {
  @include mobile {
    display: none;
  }
}

.hide_for_tablet {
  @include tablet {
    display: none;
  }
}

.socials {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 2em;
  width: min-content;
  padding-top: 2em;

  @include mobile {
    grid-template-columns: repeat(2, 1fr);
    gap: 1em 1.5em;
    padding-top: 1.5em;
    margin: auto;
  }

  @include tablet {
    grid-template-columns: repeat(2, 1fr);
  }
}

.linkButton {
  position: relative;
  width: 9em;
  font-size: 1.125em;
  font-family: 'Lato Regular';
  border-radius: 0.5em;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  padding: 0.75em 2em;
  color: #FFF;
  background-color: var(--button-bg-tertiary);

  &:hover {
    background-color: var(--button-bg-tertiary);
  }

  svg {
    margin-left: 0.75em;
    min-width: 1em;
    height: 1.125em;
  }

  svg path {
    fill: #FFF;
  }

  @include mobile {
    font-size: 1em;
  }
}
