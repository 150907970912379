@import '../../styles';

.component {
  display: flex;
  align-items: center;
  font-size: 1em;
  font-family: 'FredokaOne';
  line-height: 1.5em;
  letter-spacing: -0.02em;
}

.token {
  margin-right: 0.25em;
  width: 1.091em;
}
