@import '../../styles';








.giftTier {
  padding: 1.5em;
  background: var(--section-bg2);
  border-radius: 0.375em;
  margin-top: 2.5em;
  position: relative;

  @include mobile {
    padding: 1em;
    margin-top: 1em;
  }
}

.giftTierCover {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: var(--section-bg2);
  opacity: 0.6;
  border-radius: 0.375em;
}

.tierTop {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  z-index: $z-index-layer2;
}

.tier {
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 0.75em;
  line-height: 2.33em;
  background: var(--label-tab-bg);
  border-radius: 0.5em;
  padding: 0 0.5em;
}

.tierIcon {
  margin-right: 0.25em;

  &_gold {
    path {
      fill: #E1B749;
    }
  }

  &_silver {
    path {
      fill: #A9A9A9;
    }
  }

  &_bronze {
    path {
      fill: #99735E;
    }
  }
}

.noAvailableQuota {
  font-weight: 600;
  font-size: 0.875em;
  line-height: 2em;
  color: #B04700;
}

.tierGifts {
  position: relative;
  padding: 2em 3.75em;
  display: flex;
  align-items: center;
  justify-content: center;

  @include mobile {
    padding: 2em 1em 1em;
  }
}

.giftTierList {
  display: flex;
  gap: 1.5em;
  justify-content: flex-start;
  overflow-x: auto;

  &::-webkit-scrollbar {
    display: none;
  }

  @include mobile {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    overflow-x: hidden;
    gap: 1.25em;
  }
}

.scrollButton {
  position: absolute;
  top: calc(50% - 12px);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background: var(--button-bg-primary-hover);
  width: 1.5em;
  height: 1.5em;
  border-radius: 50%;

  svg {
    fill: #FFF;
  }

  &_previous {
    left: 0em;

    svg {
      transform: rotate(90deg);
    }
  }

  &_next {
    right: 0em;

    svg {
      transform: rotate(-90deg);
    }
  }

  @include mobile {
    display: none;
  }
}

.noTierGifts {
  width: 100%;
  text-align: center;
  padding: 5em 0;
}

.giftCard {
  background: #FFF;
  border: 0.0625em solid var(--border-color-quaternary);
  border-radius: 0.375em;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  cursor: pointer;
  min-width: 8.625em;
  max-width: 8.625em;

  @include mobile {
    min-width: unset;
  }
}

.giftCardTop {
  padding: 0.5em 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 5.25em;
}

.giftValue {
  font-weight: 600;
  font-size: 1em;
  line-height: 1.75em;
  color: #101629;
}

.giftLogo {
  display: flex;
  max-width: 6.25em;

  img {
    width: 100%;
  }
}

.giftCardBottom {
  background: var(--card-bg3);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0.5em;
  flex: 1;
  position: relative;
  min-height: 5.5em;

  @include mobile {
    min-height: 5em;
  }
}

.giftName {
  display: block;
  font-weight: 600;
  font-size: 0.75em;
  line-height: 1.25em;
  text-align: center;
}
