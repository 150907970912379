@import "../../styles";

.container {
  position: relative;
  background-color: var(--modal-bg);
  padding: 2em;
  border-radius: 0.75em;
  max-width: 25em;
  margin: auto;
  font-family: "Lato Regular";

  @include mobile {
    width: 100%;
    padding: 1.5em;
  }
}

.header {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-top: -5em;
  margin-bottom: 1.5em;
}

.wallet_coin {
  @include mobile {
    width: 7.5em;
  }
}

.close {
  position: absolute;
  bottom: 1em;
  right: 0;
  cursor: pointer;
  fill: var(--app-color);
}

.title {
  font-size: 2em;
  text-align: left;
  font-family: "FredokaOne";
}

.account {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #FFF;
  color: #101629;
  border-radius: 0.25em;
  font-family: "Lato Regular";
  padding: 0.875em;
  margin-top: .5em;
}

.address {
  margin-top: 1.5em;
}

.copy {
  width: 1em;
  cursor: pointer;

  path {
    fill: var(--svg-fill-primary);
  }
}

.disconnect {
  cursor: pointer;
  color: var(--button-color-secondary);
  background-color: transparent;
  border: var(--button-border);
  border-radius: 0.5em;
  padding: 0.625em 0;
  font-size: 1.125em;
  font-family: "Lato Regular";
  width: 100%;
}

.wallets {
  margin: 0;
  padding: 0;
  list-style: none;
  font-family: "Barlow Regular";
  font-weight: 600;
}

.wallet {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  background-color: transparent;
  padding: 1em;
  margin: 1em 0;
  border-radius: 0.5em;
  border: var(--button-border);
  cursor: pointer;
  
  &:hover {
    background-color: var(--button-bg-secondary-hover);
  }
}

.wallet_name {
  display: flex;
  align-items: center;
}

.icon {
  height: 1.25em;
  width: 1.5em;
}

.balance {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: "Lato Regular";
  line-height: 1.5em;
}

.label {
  color: var(--label-color);
}

.amount {
  font-weight: bold;
}

.swap_wcro {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1.5em;
}

.swap_href {
  svg {
    width: 1.1em;
    height: 1.1em;

    path {
      fill: var(--svg-fill-primary);
    }
  }
}

.divider {
  border-top: var(--border-primary);
  margin: 1.5em 0;
  width: 100%;
}