@import "../../styles";

.container {
  max-width: 56em;
  margin: auto;
  padding: 2em;
  font-family: "Lato Regular";

  @include mobile {
    padding: 2em 1em;
  }
}

.heading {
  font-family: "FredokaOne";

  @include mobile {
    margin-bottom: 1.5em;
  }
}

.wrapper {
  background: transparent;
}

.spinner {
  text-align: center;
  margin: 1em 0;
}

.table {
  display: grid;
  grid-template-columns: 2fr 2fr 3fr 2fr 3fr;
  background-color: var(--card-bg);

  @include mobile {
    margin-top: 1.5em;
    display: flex;
    flex-direction: column;
  }
}

.th {
  font-size: 0.875em;
  color: var(--table-cell-color);
  font-family: "Lato Regular";
  line-height: 1.3125em;
  padding: 0.875em 0;
  cursor: pointer;

  svg {
    width: 0.625em;
    margin-left: 0.5em;
  }

  &:first-child {
    padding-left: 1.5em;
  }

  &:last-child {
    padding-right: 1.5em;
  }

  @include mobile {
    display: none;
  }
}

.tr {
  grid-column: 1 / -1;
  display: grid;
  grid-template-columns: 2fr 2fr 3fr 2fr 3fr;
  border-top: var(--border-tertiary);

  @include mobile {
    display: none;
  }
}

.td {
  font-size: 1.125em;
  font-family: "Lato Regular";
  line-height: 1.75em;
  color: var(--table-cell-color);
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  padding: 1.375em 0;

  &:first-child {
    padding-left: 1.5em;
  }

  &:last-child {
    padding-right: 1.5em;
  }

  @include mobile {
    display: none;
  }
}

.name {
  text-decoration: none;
  color: #f94f01;

  @include mobile {
    font-size: 1em;
    line-height: 1em;
    font-weight: bold;
    margin-bottom: 1.5em;
  }
}

.status_wrap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.status_label {
  font-weight: 400;
  color: var(--table-cell-color);
  font-size: 0.75em;
  margin-bottom: 0.5em;
}

.status {
  font-weight: 700;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.alert_status {
  font-size: 0.75em;
  color: #555a69;
  display: flex;
  align-items: center;

  svg {
    margin-right: 0.25em;
  }

  @include mobile {
    position: absolute;
    right: 1.5em;
    top: 6.5em;
  }
}

.card {
  display: none;
  @include mobile {
    display: flex;
    flex-direction: row;
    padding: 1.5em 1em;
    border-top: var(--border-tertiary);
    position: relative;
  }
}

.card_content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.collapse_arrow {
  position: absolute;
  right: 0.5em;
  top: 38%;
  width: 2em;
  height: 2em;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.5s ease;

  svg {
    fill: var(--svg-fill-secondary);
  }
}

.collapse_arrow_opened {
  transform: rotate(180deg);
  transition: all 0.5s ease;
}

.collapse_content {
  display: none;
  transition: all 0.5s ease;
  @include mobile {
    background-color: var(--table-collpased-bg);
    padding: 1.5em 1em;
    position: relative;
  }
}

.collapse_opened {
  display: none;
  @include mobile {
    display: flex;
    flex-direction: column;
  }
}

.detail {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  margin-bottom: 0.5em;

  &:last-child {
    margin-bottom: 0;
  }
}

.detail_label {
  font-size: 1em;
  line-height: 1.5em;
  color: var(--label-color);
}

.detail_value {
  font-size: 1em;
  line-height: 1.5em;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.empty_offers {
  text-align: center;
  margin: 1em 0;
  font-family: "Lato Regular";
  color: var(--label-color);
  font-size: 1em;
}

.tracking_number {
  color: var(--title-color);
  @include mobile {
    margin-top: 0.5em;
  }
}

.dropdown_content {
  background-color: var(--dropdown-bg);
  position: absolute;
  top: 1.5em;
  z-index: 1;
  min-width: 6em;
  right: 0;
  list-style-type: none;
  padding: .5em 0 0 0;
  display: none;
  text-align: right;
}

.dropdown_item {
  font-size: 0.875em;
  line-height: 1.25em;
  font-family: "Lato Regular";
  padding: 0.75em 1.5em;
  border: var(--border-tertiary);
  border-bottom: none;
  cursor: pointer;

  &:first-child {
    border-radius: .25em .25em 0 0;
  }

  &:last-child {
    border-radius: 0 0 .25em .25em;
    border-bottom: var(--border-tertiary);
  }
}

.btn_group {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  position: relative;
}

.primary_btn {
  font-size: 0.875em;
  width: 9em;
  height: 2.5em;

  @include mobile {
    margin: 1em 1em 0 0;
  }
}

.dropdown_btn {
  font-size: 0.875em;
  background-color: var(button-bg-secondary);
  border: var(--button-border);
  border-radius: 0.5em;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 9em;
  height: 2.5em;
  margin-left: 0.5em;

  &:hover {
    background-color: var(--button-bg-secondary-hover);
  }

  svg path {
    fill: var(--svg-fill-primary);
  }

  @include mobile {
    margin-top: 1em;
    margin-left: 1em;
  }
}

.overflow_btn {
  width: 2.5em;
  border-radius: 0.5em;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &:hover {
    ul {
      display: block;
      transition-delay: 0.5s;
    }
  }
}

.error_icon {
  width: 1em;
  height: 1em;
  margin-right: 0.5em;
}
