@import '../../styles';
@import './Keyframes.scss';

.container {
  font-family: Lato Regular;
  width: 100%;
  overflow: hidden;
}

.header {
  position: relative;
}

.animation {
  display: flex;
  justify-content: center;
  width: 100%;
  overflow: hidden;
  position: relative;

  video {
    width: 100%;
  }

  &_mb {
    display: none;
  }

  @include mobile {
    &_mb {
      display: initial;
    }

    &_dt {
      display: none;
    }
  }

  // img {
  //   width: inherit;
  //   display: flex;
  // }

  // &_bg {
  //   width: 100vw;
  //   aspect-ratio: 1.85 / 1;
  // }

  // &_logo {
  //   position: absolute;
  //   top: 5em;
  //   transform: scale(0.8, 0.8);
  //   width: 25vw;
  //   opacity: 0;

  //   &_show {
  //     transition: opacity 500ms linear 6.5s;
  //     opacity: 1;
  //     animation: logoShow 1s ease-out 7s both, logoBounce 8s ease-in 12s forwards infinite;
  //   }
  // }

  // &_steve1 {
  //   position: absolute;
  //   bottom: -80%;
  //   width: 38vw;
  //   display: none;

  //   &_show {
  //     display: flex;
  //     animation: steve1Show 1s ease-out 6.5s both;
  //   }
  // }

  // &_steve2 {
  //   position: absolute;
  //   bottom: -6em;
  //   width: 40vw;
  //   display: none;

  //   &_show {
  //     display: flex;
  //     animation: steve2Vanish 200ms linear 6s forwards;
  //   }
  // }

  // &_leaf1 {
  //   width: 86vw;
  //   position: absolute;
  //   bottom: -30%;
  //   display: none;

  //   &_show {
  //     display: flex;
  //     animation: leaf1Rotate 2s ease-in-out 1s, leaf1Vanish 500ms linear 4s forwards;
  //   }
  // }

  // &_leaf2 {
  //   width: 86vw;
  //   position: absolute;
  //   bottom: -30%;
  //   display: none;

  //   &_show {
  //     display: flex;
  //     animation: leaf2Rotate 2s ease-in-out 1s, leaf2Vanish 500ms linear 5s forwards;
  //   }
  // }
}

.winner {
  position: absolute;
  top: 2em;
  width: 100%;

  @include mobile {
    top: 1em;
    left: 1em;
    right: 1em;
    width: unset;
  }
}

.winner_wrap {
  max-width: 60em;
  width: fit-content;
  margin: auto;
  display: flex;
  align-items: stretch;
  border-radius: 0.75em;
  overflow: hidden;
  border: 2px solid var(--border-color-quaternary);

  @include mobile {
    flex-direction: column;
  }
}

.winner_left {
  background: var(--table-collpased-bg);
  color: var(--app-color);
  padding: 1.5em 2em;

  @include mobile {
    padding: 0.5em 0.75em;
  }
}

.winner_chapter {
  font-size: 1.375em;
  font-family: 'FredokaOne';
  line-height: 1.5em;
  letter-spacing: -0.44px;
}

.winner_content {
  display: flex;
  align-items: center;
  gap: 1.5em;
}

.winner_img {
  display: flex;
  max-width: 9.5em;
  height: auto;
  border-radius: 0.625em;
  margin-top: 0.5em;
  border: 0.0625em solid #000;

  @include mobile {
    display: none;
  }
}

.winner_msg {
  font-size: 1.125em;
  line-height: 1.5em;
  margin: 0;

  @include mobile {
    font-size: 0.75em;
    br {
      display: none;
    }
  }
}

.winner_right {
  background: var(--page-header-bg);
  padding: 3em;
  display: flex;
  align-items: center;

  @include mobile {
    padding: 0.75em;
    justify-content: center;
  }
}

.winner_view {
  width: 8em;

  @include mobile {
    font-size: 1em;
  }
}

.body {
  padding: 3em 0 5em;
  display: flex;
  flex-direction: column;
  gap: 7.5em;

  @include mobile {
    padding: 4em 1em;
    gap: 6em;
  }
}

.section, .section_full {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.section {
  max-width: 60em;
  width: 100%;
  margin: auto;
}

.main_title {
  font-family: 'FredokaOne';
  font-size: 3em;
  line-height: 1.25em;
  letter-spacing: -0.96px;

  @include mobile {
    font-size: 1.75em;
  }
}

.main_intro {
  font-size: 1.25em;
  line-height: 1.5em;
  margin-top: 1.2em;

  @include mobile {
    br {
      display: none;
    }

    font-size: 1em;
  }
}

.redirect {
  width: 10em;
  margin-top: 1.33em;

  @include mobile {
    font-size: 1em;
  }
}

.section_title {
  font-family: 'FredokaOne';
  font-size: 2em;
  line-height: 1.75em;

  @include mobile {
    font-size: 1.375em;
  }
}

.section_desc {
  font-size: 1em;
  line-height: 1.5em;
  margin-top: 0.5em;

  a {
    color: #F94F01;
    margin: 0 0.125em;
  }

  @include mobile {
    color: var(--label-color);
  }
}

.features {
  width: 100%;
  padding: 1.75em 0;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 1em;
  position: relative;
}

.feature_item {
  padding: 2em 0;
  background: var(--table-bg);

  @include mobile {
    padding: 1.5em 0.5em;
  }
}

.feature_item_inner {
  max-width: 29em;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5em;
}

.feature_item_title {
  position: relative;
  font-size: 1.375em;
  line-height: 1.5em;
  font-weight: 900;
  letter-spacing: -0.02em;

  @include mobile {
    font-size: 1.25em;
  }
}

.feature_item_new {
  position: absolute;
  top: -0.5em;
  right: -3.5em;
  font-size: 0.625em;
  font-weight: 600;
  line-height: 1.5em;
  width: 3em;
  color: #FFF;
  background-color: #FFD133;
  border-radius: 0.2em;
}

.feature_item_time {
  font-size: 1em;
  line-height: 1.75em;
  font-weight: 500;
  background: var(--strip-bar-bg);
  border-radius: 0.125em;
  width: fit-content;
  padding: 0 1em;
}

.feature_item_desc {
  font-size: 1em;
  line-height: 1.3125em;
  width: 100%;
}

.feature_imgs {
  @include mobile {
    display: flex;
    justify-content: space-between;
  }
}

.feature_img_raffle {
  position: absolute;
  max-width: 25em;
  top: 3em;
  left: 2em;

  @include mobile {
    position: relative;
    max-width: 11em;
    top: 0em;
    left: unset;
  }
}

.feature_img_director {
  position: absolute;
  max-width: 27em;
  right: 2em;
  bottom: 3em;

  @include mobile {
    max-width: 11em;
    position: relative;
    right: unset;
    bottom: -2em;
  }
}


.work {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1em;
  padding: 3em 0;

  @include mobile {
    grid-template-columns: repeat(1, 1fr);
    padding: 2em 0;
  }
}

.work_item {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.625em;
  background: var(--table-bg);
  padding: 1.5em;
  border: 0.125em solid var(--border-color-secondary);
  box-shadow: 0.125em 0.25em 0em var(--border-color-secondary);
  border-radius: 0.75em;

  @include mobile {
    max-width: 19.25em;
  }
}

.work_item_img {
  max-width: 13.875em;
}

.work_item_title {
  font-size: 1.375em;
  line-height: 1.5em;
  font-weight: 900;
  letter-spacing: -0.02em;
  width: 100%;
  text-align: left;

  @include mobile {
    text-align: center;
  }
}

.work_item_desc {
  font-size: 1em;
  line-height: 1.3125em;
  width: 100%;
  text-align: left;
  padding-top: 0.75em;

  @include mobile {
    text-align: center;
  }
}

.leadboard_table {
  overflow: hidden;
  width: 100%;
  padding: 3em 0;

  @include mobile {
    padding: 1.5em 0;
  }
}

.table_hr {
  display: flex;
  align-items: center;
  background: var(--table-bg2);
  padding: 0.625em 1em;

  span {
    font-size: 0.875em;
    font-weight: 600;
    line-height: 1.5em;
    flex: 1;
  }

  span:first-of-type {
    flex: 0.5;
    text-align: left;
  }

  @include mobile {
    span:nth-child(3), span:nth-child(4) {
      display: none;
    }
  }
}

.table_body {
  width: 100%;
  overflow-y: auto;
  max-height: 29.5em;

  &::-webkit-scrollbar {
    width: 0.5em;
  }

  &::-webkit-scrollbar-track {
    background-color: #D9D9D9;
    border-radius: 0.125em;
  }
  
  &::-webkit-scrollbar-thumb {
    background-color: #B78E61;
    border-radius: 0.125em;
  }
}

.table_tr {
  display: flex;
  align-items: center;
  padding: 0.75em 2em;

  &:nth-child(even) {
    background: var(--table-bg);
  }

  span {
    font-size: 0.875em;
    line-height: 1.5em;
    flex: 1;
  }

  span:first-of-type {
    flex: 0.5;
    text-align: left;
    font-weight: 600;
  }

  @include mobile {
    span:nth-child(3), span:nth-child(4) {
      display: none;
    }
  }
}

.pagination {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 1.125em;
  line-height: 1.5em;
  width: 100%;
  padding-bottom: 2em;
  color: var(--title-color);

  &_disabled {
    color: #555A69;
  }
}

.ranking {
  color: var(--highlight-color);
  font-size: 1em;
}



.faqs {
  display: flex;
  justify-content: space-between;
  padding: 3em 0;
  gap: 5em;
  text-align: left;

  @include mobile {
    flex-direction: column;
    gap: 1em;
    padding: 1.5em 0;
  }
}

.faq_col {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 2em;

  @include mobile {
    gap: 1em;
  }
}
