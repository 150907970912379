@import "../../styles";

/* General */
.container {
  max-width: 24em;
  background-color: transparent;
  display: flex;
  flex-direction: column;
  border-radius: 0.75em;
  height: 90vh;
  overflow: auto;
  margin: auto;
  font-family: "Lato Regular";
  position: relative;

  &::-webkit-scrollbar {
    max-width: 0;
  }

  @include mobile {
    height: auto;
    overflow: none;
    position: initial;
  }
}

.container_without_height {
  height: auto;
}

.view_cart_container {
  max-width: 32em;
  height: auto;
}

.cancel {
  position: absolute;
  right: 1.8em;
  top: 1.8em;
  cursor: pointer;
  fill: var(--svg-fill-tertiary);

  @include mobile {
    right: 2em;
    top: 2em;
  }
}

.header {
  background-color: var(--modal-header-bg);
  padding: 1.5em;

  @include mobile {
    display: none;
  }
}

.header_view_cart {
  background-color: var(--modal-header-bg);
  padding: 1.5em;

  @include mobile {
    padding: 1em;
  }
}

.title {
  display: block;
  font-size: 1.375em;
  font-family: "Lato Bold";
  text-align: center;
  padding-bottom: 1.25em;
}

.cart_list {
  max-height: 50vh;
  padding: 0 0.25em;

  &::-webkit-scrollbar {
    width: 0.25em;
  }

  &::-webkit-scrollbar-track {
    background-color: #FFF;
  }
  
  &::-webkit-scrollbar-thumb {
    background-color: #767676;
    border-radius: 0.25em;
  }
}

.message_add_cart {
  display: block;
  font-size: 1.375em;
  font-family: "Lato Bold";
  text-align: center;
  padding: 1em 0;
}

.title_mobile {
  display: none;
  font-size: 1.375em;
  font-family: "Lato Bold";
  text-align: center;
  padding-bottom: 1.25em;

  @include mobile {
    display: block;
  }
}

.content {
  display: flex;
  flex-direction: column;
  padding: 1.5em;
  background-color: var(--modal-body-bg);
  border-bottom-left-radius: 0.75em;
  border-bottom-right-radius: 0.75em;

  @include mobile {
    border-radius: 0.75em;
  }
}

.content2 {
  @include mobile {
    align-items: center;
    padding-top: 6em;
  }
}

.content_view_cart {
  display: flex;
  flex-direction: column;
  padding: 1.5em;
  background-color: var(--modal-body-bg);

  @include mobile {
    padding: 1em;
  }
}

.label {
  font-size: 1.125em;
  margin-bottom: 0.5em;
  line-height: 1.625em;
}

.product_name {
  font-size: 1.375em;
  line-height: 2em;
}
.product_price {
  font-size: 1.125em;
  line-height: 1.625em;
  margin-bottom: 1em;
}

.product_quantity {
  display: flex;
  flex-direction: column;

  input {
    background-color: #ffffff;
    border: 0.0625em solid #c3c6cf;
    border-radius: 0.25em;
    height: 3.5em;
    padding: 0.875em 0.75em;
    width: 100%;
    font-size: 1.125em;

    &:focus {
      border: 2px solid #555a69;
      outline: none;
    }
  }

  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    opacity: 1;
  }
}

.product_size {
  display: flex;
  flex-direction: column;
  margin-top: 1em;
}

.product_size_list {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;

  @include mobile {
    justify-content: space-between;
  }

  span {
    font-size: 1.125em;
    color: var(--button-color-secondary);
    border: var(--button-border);
    border-radius: 0.375em;
    padding: 0.625em 0.5em;
    width: 1.25em;
    height: 0.75em;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin-bottom: 0.5em;
    margin-right: 0.5em;

    @include mobile {
      margin: 0;
    }
  }

  .size_not_disabled {
    &:hover {
      background-color: var(--button-bg-primary);
      color: #FFF;
    }
  }

  .size_active {
    background-color: var(--button-bg-primary);
    color: #FFF;
  }

  .size_disabled {
    border: none;
    background-color: grey;
    color: #FFF;
  }
}

.slider {
  height: 18em;

  div {
    &:focus {
      outline: none;
    }
  }
}

.product_image {
  width: 100% !important;
  display: flex;
  display: flex !important;
  justify-content: center;
  align-items: flex-start;
  img {
    width: 80%;
  }
}

.btn {
  margin-top: 1em;
}

.add_to_cart_image_mobile {
  width: 10em;
  height: 10em;
  background-color: var(--data-box-bg);
  justify-content: center;
  align-items: center;
  border-radius: 0.75em;
  display: none;
  position: absolute;
  top: -80px;

  @include mobile {
    display: flex;
  }

  img {
    width: 77%;
  }
}

.sub_total {
  display: flex;
  justify-content: space-between;
}

.sub_total_label {
  color: var(--label-color);
  font-size: 1.25em;
}

.sub_total_value {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  line-height: 1.5em;

  span {
    font-size: 1.125em;
  }
}

.shipping_message {
  margin-top: 1.5em;
  margin-bottom: 1em;
}

.error_label {
  font-size: 1.125em;
  line-height: 1.625em;
  color: #F65166;
  margin-bottom: 1em;
}
.label_quantity {
  font-family: 'Lato';
  font-style: italic;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  color: #101629;
}