@import "../../styles";

.component {
  position: relative;
  display: flex;
  cursor: pointer;
  overflow: hidden;
  border-radius: 0.625em;
  width: 9.5em;
  aspect-ratio: 1.9 / 1;

  &_selected {
    width: 10.75em;
    border: 0.125em solid var(--border-color-secondary);
  }

  @include mobile {
    width: 5.5em;
    border-radius: 0.375em;

    &_selected {
      width: 7.75em;
    }  
  }
}

.img {
  width: 100%;
  height: auto;
  display: flex;
}

.locked {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(139, 139, 139, 0.5);
  backdrop-filter: blur(10px);
  border-radius: 0.5em;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @include mobile {
    border-radius: 0.25em;
  }
}

.locked_icon {
  width: 0.875em;
  height: 1.25em;

  path {
    fill: #FFF;
  }

  @include mobile {
    height: 1em;
  }
}
