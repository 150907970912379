@import '../../styles';

.button {
  display: none;
  position: fixed;
  bottom: 2em;
  right: 1em;
  color: #FFF;
  background-color: var(--button-bg-primary);
  border-radius: 1em;
  border: none;
  align-items: center;
  justify-content: center;
  padding: .75em 1.25em;
  font-size: .875em;
  font-family: 'Lato Regular';
  z-index: $z-index-layer2;
  cursor: pointer;

  &_show {
    display: flex;
  }

  @include mobile {
    bottom: 1em;
  }
}

.icon {
  height: 0.875em;
  margin-left: .25em;
  margin-top: .125em;
}