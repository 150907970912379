@import '../../styles';

.container {
  max-width: 42em;
  margin: auto;
  padding: 2em;
  
  &_wider {
    max-width: 49em;
  }

  @include mobile {
    padding: 2em 1em;
  }
}

.back {
  display: flex;
  align-items: center;

  svg {
    fill: var(--svg-fill-tertiary);
  }
}

.back_label {
  font-size: 1.125em;
  font-family: 'Barlow SemiBold';
  text-decoration-line: underline;
  padding-left: 1em;
  cursor: pointer;
}

.traits {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1em;
  width: 100%;

  @include mobile {
    grid-template-columns: repeat(2, 1fr);
    gap: .5em;
  }

  @include tablet {
    grid-template-columns: repeat(3, 1fr);
  }
}

.detail_row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 1.125em;
  margin-bottom: 1em;
}

.detail_label {
  font-family: 'Barlow Regular';
}

.detail_value {
  font-family: 'Barlow Bold';

  a {
    color: var(--app-color);
  }
}

.transaction_table {
  width: 100%;
  border: 0;
  margin: 0;
  padding: 0;
  table-layout: fixed;
  border-collapse: collapse;
}

.transaction_row {
  padding: 1em;
}

.transaction_header_row {
  padding: 1em;
}

.transaction_header_column {
  padding: .3em;
  font-family: 'Barlow Bold';
  font-size: 1.125em;
  text-align: left;
}

.transaction_column {
  padding: .3em;
  font-family: 'Barlow Regular';
  font-size: 1.125em;
  text-align: left;

  a {
    color: var(--app-color);
  }
}

@media screen and (max-width: 575.99px) {
  .transaction_header_row, .transaction_header_column {
    display: none;
  }

  .transaction_row {
    display: block;
    padding: 0;
    margin-bottom: 1em;
  }

  .transaction_column {
    display: block;
    text-align: right;
  }

  .transaction_column::before {
    float: left;
    font-weight: bold;
    content: attr(data-label);
  }
}

.notFound {
  font-size: 2em;
  font-family: 'Barlow Bold';
  margin: 2em 0;
  text-align: center;
}
