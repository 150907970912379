@import '../../styles';

.connect_button {
  position: relative;
  min-width: 9.375em;
  background-color: var(--button-bg-tertiary);
  color: var(--button-color-primary);
  border-radius: 6.25em;
  padding: 0.875em 0;
  cursor: pointer;
  font-family: 'Lato Regular';
  display: flex;
  align-items: center;
  justify-content: center;

  &_wrong {
    padding: 0.25em 0.625em 0.25em 0.25em;
  }

  @include mobile {
    width: 100%;
    margin: 2.5em 0;

    &_wrong {
      width: fit-content;
      align-self: center;
    }
  }
}

.container {
  position: relative;
  width: 11.75em;
  display: none;
  background-color: var(--wallet-bg);
  border-radius: 6.25em;
  padding: 0.25em;
  cursor: pointer;
  font-family: 'Lato Regular';
  
  &_show {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  @include mobile {
    width: 100%;
    background-color: transparent;
    font-weight: bold;
    border-radius: 0;
    padding: 0;
  }
}

.text {
  &_connected {
    display: none;
  }


  @include mobile {
    &_connected {
      display: initial;
    }
  }

}

.icon {
  width: 2.5em;
  height: 2.5em;
  border-radius: 50%;
  margin-right: 0.625em;
}

.icon_warning {
  width: 2.5em;
  height: 2.5em;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 0.625em;

  @include mobile {
    width: 2em;
    height: 2em;
  }
}

.expand_arrow {
  width: 2em;
  height: 2em;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 0.5em;

  svg {
    fill: var(--svg-fill-secondary);
  }

  @include mobile {
    display: none;
  }
}

.cart_online_icon {
  position: absolute;
  top: -0.25em;
  right: 0;
  background-color: var(--nav-bg);
  width: 1em;
  height: 1em;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;

  svg {
    width: 0.75em;
    height: 0.75em;
  }

  @include mobile {
    display: none;
  }
}