@import '../../styles';

.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: .5em 0;

  &_reversed {
    flex-direction: row-reverse;
  }
}

.label {
  width: 100%;

  &_reversed {
    padding-left: 0.875em;
  }
}

.right {
  display: flex;
  align-items: center;
}

.icon {
  height: 1em;
  width: 1em;
}

.count {
  text-align: right;
  padding-right: 1em;
}
