@import '../../styles';

.container {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #4F4F4FCF;
  z-index: $z-index-modal;
}

.body {
  position: absolute;
  left: 5%;
  right: 5%;
  top: 5%;
  bottom: 5%;
  background-color: #FFF;
  display: flex;
  align-items: center;
  justify-content: center;

  @include mobile {
    left: 0%;
    right: 0%;
    top: 0%;
    bottom: 0%;
  }
}

.cross {
  position: absolute;
  top: 1em;
  right: 1em;
  cursor: pointer;
}

.content {
  display: flex;
  align-items: center;
  flex-direction: column;
  max-width: 32em;
  max-height: 32em;
}

.loading {
  font-size: 2em;
  font-family: 'Barlow Bold';

  @include mobile {
    font-size: 1em;
  }
}

.error {
  font-size: 2em;
  font-family: 'Barlow Bold';

  @include mobile {
    font-size: 1em;
  }
}

.displayImage {
  width: 100%;
}

.download {
  font-family: 'Barlow Regular';
  text-align: center;
  margin-top: 1em;

  a {
    color: #333333;
  }

  @include mobile {
    display: none;
  }
}