@import './styles';
@import './styles/theme';

html, body {
  width: 100%;
  height: 100%;
}

body {
  margin: 0;
  background-color: var(--app-bg);
  color: var(--app-color);
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  height: 100%;
}

* {
  box-sizing: border-box;
}

input {
  outline: none;
  
  &::placeholder {
    // color: var(--input-color-primary);
    color: #C3C6CF;
  }

  &::-webkit-input-placeholder {
    // color: var(--input-color-primary);
    color: #C3C6CF;
  }

  &:-ms-input-placeholder {
    // color: var(--input-color-primary);
    color: #C3C6CF;
  }
}

@include mobile {
  ::-webkit-scrollbar {
    display: none;
  }
}

@include tablet {
  ::-webkit-scrollbar {
    display: none;
  }
}
